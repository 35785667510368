import {css} from 'lit';
export const styles = css`clb-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 48px;
  padding: 0 var(--spacing-size-3xs);
}

.dropdown-checkbox-item--highlighted {
  background-color: var(--color-brand-100);
}`;
export default styles;
