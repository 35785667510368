import { html, LitElement } from 'lit'
import { property, queryAssignedElements } from 'lit/decorators.js'
import { when } from 'lit/directives/when.js'

import { ButtonAttributes } from '@components/button-group/props.types'

import { ClbMixin } from '@utils/ClbMixin'
import { createEvent, bubbleEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import { Types } from './props.types'
import styles from './styles.scss'

import '@components/button-group'

@registerElement('clb-dropdown')
export class ClbDropdown extends ClbMixin(LitElement) {
  @property({ type: String }) type: Types = 'default'

  @property({ type: Array }) buttonsList: Array<ButtonAttributes> = []

  @property({ type: Boolean }) open = false

  @property({ type: String }) width = '100%'

  @queryAssignedElements({
    slot: ClbDropdown.slots.content,
    flatten: false
  })
  _contentElements: Array<LitElement & { open?: boolean }>

  static styles = [styles]

  static events = {
    onDropdownVisibilityChange: 'onDropdownVisibilityChange'
  }

  static slots = {
    trigger: 'dropdown-trigger',
    content: 'dropdown-content'
  }

  protected firstUpdated(): void {
    this._handlePosition()
  }

  protected updated(): void {
    this._handlePosition()
  }

  connectedCallback(): void {
    super.connectedCallback()
    window.addEventListener('click', this._handleClickOutside)
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    window.removeEventListener('click', this._handleClickOutside)
  }

  _handleClickOutside = (event: MouseEvent) => {
    const path = event.composedPath()
    if (this.open && !path.includes(this)) {
      this._toggleVisibility(event, false)
    }
  }

  _toggleVisibility = (evt: Event, newState = !this.open) => {
    const event = createEvent(ClbDropdown.events.onDropdownVisibilityChange, {
      cancelable: true,
      detail: { open: newState }
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)

    if (this.dispatchEvent(event)) {
      this.open = newState
    }
  }

  get root(): any {
    return this.shadowRoot || this
  }

  _handlePosition() {
    const triggerElement: HTMLElement =
      this.root.querySelector('#dropdown-trigger')

    const containerElement: HTMLElement = this.root.querySelector('#dropdown')

    const dropdownList: HTMLElement = this.root.querySelector('#dropdown-list')

    if (!triggerElement) {
      this.open = false
      return
    }

    const {
      x: triggerX,
      y: triggerY,
      height: triggerHeight
    } = triggerElement.getBoundingClientRect()

    const { height: dropdownHeight, width: dropdownWidth } =
      containerElement.getBoundingClientRect()

    const { innerWidth: windowWidth, innerHeight: windowHeight } = window

    const marginOffset = 5
    const buttonGroupHeight = 35

    const contentHeight =
      dropdownHeight +
      marginOffset +
      (this.type === 'multiselect' ? buttonGroupHeight : 0)

    const triggerBottom = triggerY + triggerHeight

    const newPosition = {
      left: undefined,
      top: undefined,
      right: undefined,
      bottom: undefined
    }

    if (windowHeight < triggerBottom + contentHeight) {
      newPosition.top = undefined
      newPosition.bottom = triggerHeight + marginOffset
    } else {
      newPosition.top = triggerHeight + marginOffset
      newPosition.bottom = undefined
    }

    if (windowWidth < triggerX + dropdownList?.offsetWidth) {
      newPosition.left = -dropdownList?.offsetWidth + dropdownWidth
      newPosition.right = 0
    } else {
      newPosition.left = 0
      newPosition.right = undefined
    }

    Object.entries(newPosition).forEach(([key, value]) => {
      containerElement.style.setProperty(
        key,
        value === undefined ? 'unset' : `${value}px`
      )
    })
  }

  _handleTriggerClick = (event: Event) => {
    this._toggleVisibility(event)
  }

  renderDropdown() {
    return when(
      this.open,
      () => html`<div
        class="dropdown__list"
        aria-label="dropdown-list"
        role="combobox"
        aria-expanded="${this.open}"
        style="min-width: ${this.type === 'default'
          ? '224px'
          : '376px'}; width: ${this.width}"
      >
        <input
          type="hidden"
          aria-multiline="false"
          role="textbox"
          aria-controls="dropdown-list"
          aria-readonly="true"
        />
        <div
          class="dropdown__content"
          id="dropdown-list"
          role="listbox"
          aria-label="dropdown-content"
          aria-owns="dropdown-content-slot"
          aria-controls="dropdown-content-slot"
        >
          <slot
            id="dropdown-content-slot"
            name="${ClbDropdown.slots.content}"
          ></slot>
        </div>
        ${when(
          this.type === 'multiselect',
          () =>
            html`
              <div class="dropdown__footer">
                <clb-button-group
                  .buttonsList="${this.buttonsList}"
                  size="sm"
                ></clb-button-group>
              </div>
            `
        )}
      </div>`
    )
  }

  render() {
    return html`
      <div class="dropdown">
        <div id="dropdown-trigger" class="dropdown__trigger">
          <slot
            name="${ClbDropdown.slots.trigger}"
            @click=${this._handleTriggerClick}
          ></slot>
        </div>
        <div id="dropdown" class="dropdown__container">
          ${this.renderDropdown()}
        </div>
      </div>
    `
  }
}
