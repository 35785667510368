import {css} from 'lit';
export const styles = css`.dropdown-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 48px;
  color: var(--color-neutral-600);
  font-size: var(--font-size-default);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
  padding: 0 var(--spacing-size-3xs);
  cursor: pointer;
}
.dropdown-item:hover {
  background-color: var(--color-action-hover);
}
.dropdown-item--disabled {
  cursor: default;
  color: var(--color-neutral-300);
  background-color: var(--color-neutral-200);
}
.dropdown-item--disabled:hover {
  background-color: var(--color-neutral-200);
}`;
export default styles;
