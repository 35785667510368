import {css} from 'lit';
export const styles = css`.drawer {
  position: fixed;
  display: flex;
  right: 0;
  top: 0;
  flex-direction: column;
  border: var(--border-width-default) var(--color-neutral-200) solid;
  width: 33.3vw;
  height: 100vh;
  min-height: -webkit-fill-available;
  transform: translateX(100%);
  transition: 0.5s ease;
  background-color: var(--color-neutral-100);
}
.drawer--opened {
  transform: translateX(0);
}
.drawer--responsive {
  width: 100%;
}
.drawer__content {
  padding: var(--spacing-inset-size-lg);
  color: var(--color-neutral-600);
  height: 100%;
  overflow-y: auto;
}
.drawer__content::-webkit-scrollbar {
  width: var(--border-width-lg);
  border-radius: var(--border-radius-default);
  background: var(--color-neutral-200);
}
.drawer__content::-webkit-scrollbar-thumb {
  background: var(--color-neutral-400);
  border-radius: var(--border-radius-default);
}
.drawer__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.drawer__close {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: calc(0rem - var(--spacing-inset-size-lg));
  padding-top: var(--spacing-size-3xs);
}
.drawer__buttonGroup-responsive {
  display: block;
  width: 100%;
}
.drawer__footer {
  display: flex;
  justify-content: end;
  margin-top: auto;
  padding: var(--spacing-inset-size-lg);
  background-color: var(--color-neutral-background);
}

.drawer__fadein {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: block;
  background: var(--color-opacity-default);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}`;
export default styles;
