import { html, LitElement } from 'lit'
import { property, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { when } from 'lit/directives/when.js'

import { ClbMixin } from '@utils/ClbMixin'
import { createEvent, bubbleEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

import '@components/button-icon'
import '@components/icon'

@registerElement('clb-tag')
export class ClbTag extends ClbMixin(LitElement) {
  @property({ type: String }) id = ''

  @property({ type: String }) type = 'neutral'

  @state() icon = ''

  @property({ type: String }) label = ''

  @property({ type: Boolean }) disabled = false

  @property({ type: Number, reflect: false }) tabindex = 0

  static styles = [styles]

  static events = {
    onClbRemove: 'onClbRemove'
  }

  get patternType() {
    switch (this.type) {
      case 'negative':
        this.icon = 'Spam'
        break
      case 'warning':
        this.icon = 'Warning'
        break
      case 'positive':
        this.icon = 'Check'
        break
      default:
        this.icon = ''
        break
    }
    return ['neutral', 'negative', 'warning', 'positive', 'filter'].includes(
      this.type
    )
      ? this.type
      : 'neutral'
  }

  handleClickRemoveButton(evt: Event) {
    // TODO: change click handler
    const event = createEvent(ClbTag.events.onClbRemove, {
      detail: {
        id: this.id
      }
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)
    this.dispatchEvent(event)
  }

  get labelText() {
    const slotElement = this.shadowRoot.querySelector('slot') as HTMLSlotElement
    const childNodes = slotElement.assignedNodes({ flatten: true })

    let label = ''

    for (let index = 0; index < childNodes.length; index++) {
      const element = childNodes[index] as any

      if (element.data.indexOf('\n') == -1) {
        label = element.data
      }
    }

    return label
  }

  render() {
    return html`
      <div
        tabindex="${this.tabindex}"
        ?disabled=${this.disabled}
        id="tag-${this.id}"
        class="${classMap({
          tag: true,
          [`tag--${this.patternType}`]: true
        })}"
      >
        ${when(
          this.icon,
          () => html` <clb-icon size="sm" icon="${this.icon}"></clb-icon> `
        )}
        <span class="label-tag"> ${this.label} </span>
        ${when(
          this.type == 'filter',
          () => html`
            <clb-icon
              size="sm"
              icon="Close"
              title="click para remover essa tag"
              @click="${this.handleClickRemoveButton}"
            ></clb-icon>
          `
        )}
      </div>
    `
  }
}
