import { LitElement } from 'lit'

type dispatchCustomEventsProps = {
  eventName: string
  dispatcher: LitElement | HTMLInputElement
  eventOptions?: unknown
  targetElement?: Element
}

/**
 * @summary Isso é uma abstração das funções bubbleEvent e createEvent
 * @description Gera um CustomEvent e retorna o dispatchEvent a partir de um litElement
 * @param {string} eventName Nome do evento customizado
 * @param {CustomEventInit} eventOptions Propriedades do evento
 * @param {LitElement} dispatcher LitElement que irá despachar o evento
 * @param {Element} [targetElement] Elemento alvo do evento
 * @returns boolean - resultado do dispatchEvent
 */
export function dispatchCustomEvent(props: dispatchCustomEventsProps): boolean {
  const { eventName, eventOptions, dispatcher, targetElement } = props
  const event = createEvent(eventName, eventOptions)
  if (targetElement) {
    bubbleEvent(event, targetElement)
  }
  return dispatcher.dispatchEvent(event)
}

export function bubbleEvent(event: Event, targetElement: Element): void {
  Object.defineProperties(event, {
    composed: { value: true },
    bubbles: { value: true },
    target: { value: targetElement }
  })
}

export function createEvent<T>(eventName: string, options?: any) {
  return new CustomEvent<T>(eventName, {
    composed: { value: true },
    bubbles: { value: true },
    cancelable: { value: false },
    ...options
  })
}
