import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import { when } from 'lit/directives/when.js'

import '@components/checkbox'
import '@components/table-thead-cell'
import { ClbTableTHeadCell } from '@components/table-thead-cell'
import styles from '@components/table/styles.scss'

import { ClbMixin } from '@utils/ClbMixin'
import { bubbleEvent, createEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

@registerElement('clb-table-thead-row')
export class ClbTableTHeadRow extends ClbMixin(LitElement) {
  @property({ type: Boolean }) selectable = false
  @property({ type: Boolean }) checked = false
  @property({ type: Boolean }) indeterminate = false

  static styles = [styles]

  static events = {
    onClbSelectTheadRow: 'onClbSelectTheadRow'
  }

  _handleCheck = (evt: CustomEvent) => {
    const event = createEvent(ClbTableTHeadRow.events.onClbSelectTheadRow, {
      bubbles: true,
      cancelable: true,
      composed: true,
      detail: evt.detail
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)

    if (this.dispatchEvent(event)) {
      this.checked = evt.detail.checked
      this.indeterminate = evt.detail.indeterminate
    }
  }

  renderCheckboxCell = () => {
    return html`<clb-table-thead-cell class="thead-checkbox">
      <clb-checkbox
        slot="${ClbTableTHeadCell.slots.content}"
        ?checked=${this.checked}
        ?indeterminate=${this.indeterminate}
        @onClbChange=${this._handleCheck}
      ></clb-checkbox>
    </clb-table-thead-cell>`
  }

  render() {
    return html`
      ${when(this.selectable, this.renderCheckboxCell)}
      <slot></slot>
    `
  }
}
