import {css} from 'lit';
export const styles = css`.button-group {
  display: flex;
}
.button-group--line {
  flex-direction: row;
}
.button-group--line clb-button {
  margin-right: 1rem;
  z-index: 2;
}
.button-group--line clb-button:last-child {
  margin-right: 0;
}
.button-group--list {
  flex-direction: column;
}
.button-group--list clb-button {
  margin-bottom: 1rem;
}
.button-group--list clb-button:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 450px) {
  .button-group {
    flex-wrap: wrap;
  }
  .button-group clb-button {
    margin-top: var(--spacing-size-3xs);
  }
}`;
export default styles;
