import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import { when } from 'lit/directives/when.js'

import styles from '@components/table/styles.scss'

import '@components/checkbox'
import { ClbMixin } from '@utils/ClbMixin'
import { bubbleEvent, createEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

@registerElement('clb-table-tbody-row')
export class ClbTableTBodyRow extends ClbMixin(LitElement) {
  @property({ type: Boolean }) selectable = false
  @property({ type: Boolean }) checked = false

  static styles = [styles]

  static events = {
    onClbSelectRow: 'onClbSelectRow'
  }

  _handleCheck = (e: CustomEvent) => {
    const event = createEvent(ClbTableTBodyRow.events.onClbSelectRow, {
      cancelable: true,
      detail: {
        ...e.detail,
        id: this.id
      }
    })
    bubbleEvent(event, e.composedPath()[0] as Element)

    if (this.dispatchEvent(event)) {
      this.checked = e.detail.checked
    }
  }

  renderCheckbox = () => {
    return html`<clb-table-tbody-cell class="tcell-checkbox">
      <clb-checkbox
        ?checked=${this.checked}
        @onClbChange=${this._handleCheck}
      ></clb-checkbox>
    </clb-table-tbody-cell>`
  }

  render() {
    return html`
      ${when(this.selectable, this.renderCheckbox)}
      <slot></slot>
    `
  }
}
