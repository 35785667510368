import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'

import '@components/icon'
import '@components/tooltip'
import { Icons } from '@components/icon/props.types'
import { Position } from '@components/tooltip/props.types'

import { ClbMixin } from '@utils/ClbMixin'
import { bubbleEvent, createEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import { Sizes } from './props.types'
import styles from './styles.scss'

@registerElement('clb-button-icon')
export class ClbButtonIcon extends ClbMixin(LitElement) {
  @property({ type: String }) size: Sizes = 'lg'
  @property({ type: String }) icon: Icons = 'Home'
  @property({ type: String }) tooltipLabel = ''
  @property({ type: String }) tooltipPosition: Position = 'bottom'
  @property({ type: Boolean }) iconColorIsBlack = false
  @property({ type: Boolean }) disabled = false
  @property({ type: Boolean }) active = false
  @property({ attribute: 'aria-label', type: String }) ariaLabel = ''

  static styles = [styles]

  static events = {
    onClbClick: 'onClbClick'
  }

  private _bubbleEvent = (evt: Event): void => {
    const event = createEvent(ClbButtonIcon.events.onClbClick, {
      cancelable: true,
      detail: ''
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)
    this.dispatchEvent(event)
  }

  private verifyDisabledAriaLabel = (disabled: boolean): string => {
    let arialabel = ''

    if (disabled) {
      arialabel = 'desabilitado'
    }
    return arialabel
  }

  renderButton() {
    return html`
      <button
        class="${classMap({
          [`button-icon`]: true,
          [`button-icon--${this.size}`]: !!this.size,
          [`button-icon--active`]: this.active,
          [`button-icon--black`]: this.iconColorIsBlack
        })}"
        ?disabled="${this.disabled}"
        @click="${this._bubbleEvent}"
        aria-label="${this.ariaLabel ||
        `botão de ícone ${this.verifyDisabledAriaLabel(this.disabled)}`}"
      >
        <clb-icon
          icon=${this.icon}
          size=${this.size == 'lg' ? 'md' : 'sm'}
          type="${this.disabled ? 'disabled' : 'neutral'}"
        ></clb-icon>
      </button>
    `
  }

  render() {
    return this.tooltipLabel
      ? html`
          <clb-tooltip
            label=${this.tooltipLabel}
            position=${this.tooltipPosition}
          >
            ${this.renderButton()}
          </clb-tooltip>
        `
      : this.renderButton()
  }
}
