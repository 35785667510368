import {css} from 'lit';
export const styles = css`.calendar {
  background: var(--color-neutral-100);
  border: var(--border-width-xs) solid var(--color-neutral-100);
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing-inset-size-sm);
}
.calendar__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--spacing-size-4xs);
}
.calendar__header .year-month {
  display: flex;
}
.calendar__header .year-month :first-child {
  margin-right: var(--spacing-size-4xs);
}
.calendar__date {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calendar__week {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 41px);
  justify-content: space-between;
}
.calendar__week p {
  width: 41px;
  text-align: center;
  font-size: var(--font-size-sm);
  font-family: var(--font-family-base);
  color: var(--color-neutral-600);
}
.calendar__days {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 41px);
  grid-template-rows: repeat(5, 1fr);
  justify-content: space-between;
  gap: 5px;
}
.calendar__days clb-calendar-day {
  width: 0;
}
.calendar__back-current-day {
  width: 100%;
  text-align: center;
  padding: var(--spacing-size-2xs) 0 var(--spacing-inset-size-sm) 0;
}
.calendar__back-current-day p {
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  position: relative;
  padding-bottom: var(--spacing-size-5xs);
  margin: 0 auto;
  width: fit-content;
  cursor: pointer;
}
.calendar__back-current-day p:after {
  content: "";
  position: absolute;
  right: 0rem;
  bottom: 0rem;
  height: var(--border-radius-sm);
  width: var(--line-height-default);
  background-color: var(--color-neutral-600);
  transition-property: width;
  transition-duration: var(--velocity-slow);
  transition-timing-function: var(--vibe-soft);
  border-radius: var(--border-radius-sm);
}
.calendar__back-current-day p:hover {
  text-decoration: none;
}
.calendar__back-current-day p:hover::after {
  width: 50%;
}
.calendar__back-current-day p:focus:not(:focus-visible) {
  outline: transparent;
}
.calendar__back-current-day p:focus-visible {
  outline: transparent;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 0 0 2px var(--color-contrast-focused);
}`;
export default styles;
