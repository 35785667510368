import { html, LitElement } from 'lit'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg.js'
import { property } from 'lit/decorators.js'
import { ClassInfo } from 'lit/directives/class-map'
import { classMap } from 'lit/directives/class-map.js'

import * as Assets from '@celebration/assets/dist'

import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

import { Sizes, Icons, Types } from './props.types'
import styles from './styles.scss'

@registerElement('clb-icon')
export class ClbIcon extends ClbMixin(LitElement) {
  @property({ type: String }) size: Sizes = 'md'

  @property({ type: String }) icon: Icons = 'Home'

  @property({ type: String }) type: Types = 'neutral'

  @property({ type: Boolean }) baseIcon = false

  static styles = [styles]

  private getClassAttributes = (): ClassInfo => ({
    [`icon`]: true,
    [`icon--base`]: this.baseIcon,
    [`icon--${this.type}`]: this.type,
    [`icon--${this.size}`]: !this.baseIcon && this.size
  })

  render() {
    return html`
      <span class="${classMap(this.getClassAttributes())}">
        ${unsafeSVG(Assets.icons[this.icon])}
      </span>
    `
  }
}
