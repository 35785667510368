import { html, LitElement } from 'lit'
import { property, state } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import { when } from 'lit/directives/when.js'

import '@components/button-icon'
import { icons } from '@celebration/assets/dist'

import { Sizes, InputElement } from '@components/input-base/props.types'

import { ClbMixin } from '@utils/ClbMixin'
import { dispatchCustomEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-input-counter')
export class ClbInputCounter extends ClbMixin(LitElement) {
  @property({ type: String }) label = 'Label'

  @property({ type: String }) name = ''

  @property({ type: String }) id = 'counter'

  @property({ type: String }) size: Sizes = 'lg'

  @property({ attribute: 'helper-text', type: String }) helperText = ''

  @property({ type: Number }) maxvalue = 10

  @property({ type: Number }) value = 0

  @property({ type: String }) optionalMessage = 'opcional'

  @property({ type: String }) requiredMessage = 'obrigatório'

  @property({ type: Boolean }) showMessage = false

  @property({ type: Boolean }) showHelperText = false

  @property({ type: Boolean }) required = false

  @property({ type: Boolean }) disabled = false

  @property({ type: Boolean }) error = false

  @state() disableIconMinus = false
  @state() disableIconPlus = false

  static styles = [styles]

  static events = {
    onClbChange: 'onClbChange'
  }

  private validateValueLimit = () => {
    if (this.value <= 0) {
      this.value = 0
      this.disableIconMinus = true
      this.disableIconPlus = false
      return
    }
    if (this.value >= this.maxvalue) {
      this.value = this.maxvalue
      this.disableIconPlus = true
      this.disableIconMinus = false
      return
    }
    this.disableIconMinus = false
    this.disableIconPlus = false
  }

  protected firstUpdated(): void {
    this.validateValueLimit()
  }

  private getLabelClasses = (): ClassInfo => ({
    [`input-counter-label`]: true,
    [`input-counter-label--disabled`]: this.disabled,
    [`input-counter-label--${this.size}`]: true
  })

  private getInputClasses = (): ClassInfo => ({
    [`input-counter-input-container`]: true,
    [`input-counter-input-container--${this.size}`]: true,
    [`input-counter-input-container--error`]: this.error,
    [`input-counter-input-container--disabled`]: this.disabled,
    [`input-counter-input-container-icon-minus--disabled`]:
      this.disabled || this.disableIconMinus,
    [`input-counter-input-container-icon-plus--disabled`]:
      this.disabled || this.disableIconPlus
  })

  private getHelperClasses = (): ClassInfo => ({
    [`input-counter-helper`]: true,
    [`input-counter-label--disabled`]: this.disabled,
    [`input-counter-helper--error`]: this.error && this.helperText
  })

  private _dispatchCustomEvent(targetElement: InputElement): void {
    dispatchCustomEvent({
      eventName: ClbInputCounter.events.onClbChange,
      eventOptions: { detail: { value: this.value } },
      targetElement,
      dispatcher: this
    })
  }

  private _handleInput(evt: Event): void {
    const targetEvt = evt.target as HTMLInputElement
    this.value = Number(targetEvt.value)

    this.validateValueLimit()
    this.requestUpdate('value')
    targetEvt.value = String(this.value)

    this._dispatchCustomEvent(targetEvt)
  }

  private _handleButtonClick(buttonType: string) {
    let inputValue = this.value
    if (buttonType === 'plus' && this.value < this.maxvalue) {
      inputValue += 1
    } else if (buttonType === 'minus' && this.value > 0) {
      inputValue -= 1
    }
    this.value = inputValue
    this.validateValueLimit()

    const targetElement =
      this.shadowRoot.firstElementChild.querySelector('input')
    targetElement.value = String(this.value)

    this._dispatchCustomEvent(targetElement)
  }

  render() {
    return html`
      <div class="input-counter">
        <label for=${this.id} class="${classMap(this.getLabelClasses())}">
          ${this.label}
          ${when(
            this.required,
            () =>
              this.showMessage
                ? html`
                    <span class="input-counter-label--message"
                      >(${this.requiredMessage})</span
                    >
                  `
                : null,
            () =>
              this.showMessage
                ? html`
                    <span class="input-counter-label--message"
                      >(${this.optionalMessage})</span
                    >
                  `
                : null
          )}
        </label>
        <div class="${classMap(this.getInputClasses())}" tabindex="0">
          <clb-button-icon
            icon="SubtractRounded"
            size="sm"
            aria-label="incrementar valor"
            ?disabled="${this.disabled || this.disableIconMinus}"
            @click=${() => this._handleButtonClick('minus')}
          ></clb-button-icon>
          <input
            id=${this.id}
            name="${this.name}"
            .value="${this.value}"
            ?disabled="${this.disabled}"
            @input=${this._handleInput}
            aria-label="${this.label}"
            type="number"
          />
          <clb-button-icon
            icon="AddRounded"
            size="sm"
            aria-label="decrementar valor"
            ?disabled="${this.disabled || this.disableIconPlus}"
            @click=${() => this._handleButtonClick('plus')}
          ></clb-button-icon>
        </div>
        ${when(
          this.showHelperText,
          () => html`
            <span class="${classMap(this.getHelperClasses())}">
              <span>${unsafeSVG(icons['Spam'])}</span>
              ${this.helperText}
            </span>
          `,
          () => null
        )}
      </div>
    `
  }
}
