import {css} from 'lit';
export const styles = css`.RadioGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-size-3xs);
}
.RadioGroup[orientation=line] {
  flex-direction: row;
  gap: var(--spacing-size-2xs);
}`;
export default styles;
