import { html, LitElement } from 'lit'
import { property, state } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'
import { when } from 'lit/directives/when.js'

import '@components/dropdown'
import '@components/button-icon'
import '@components/text'
import '@components/select'

import { ClbMixin } from '@utils/ClbMixin'
import { dispatchCustomEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import { OptionAttributes } from '../select/props.types'
import { Sizes, optionsSelect } from './props.types'
import styles from './styles.scss'

@registerElement('clb-pagination')
export class ClbPagination extends ClbMixin(LitElement) {
  @property({ type: Number }) currentPage = 0

  @property({ type: String }) size: Sizes = 'lg'

  @property({ type: Boolean }) disabled = false

  @property({ type: Number }) totalItems = 0

  @property({ type: Number }) totalPages = 0

  @property({ type: Boolean }) showResult = false

  @property({ type: Array }) selectItemsPerPage = optionsSelect

  @property({ type: Number }) itemsPerPage = 10

  @state() private lastWidth = window.innerWidth

  @state() private visibleButtons = 0

  @state() private disabledInternal = false

  @state() private control = true

  static styles = [styles]

  static events = {
    onClbChangePage: 'onClbChangePage',
    onClbSelectChange: 'onClbSelectChange'
  }

  private setValuesSelect() {
    const valuesSelect: Array<OptionAttributes> = []

    this.selectItemsPerPage.map((value, index) => {
      valuesSelect.push({
        label: value.toString(),
        value: value.toString(),
        disabled: false,
        key: index
      })
    })

    return valuesSelect
  }

  private getClassesPagination = (): ClassInfo => ({
    [`pagination`]: true,
    [`pagination--disabled`]: this.disabledInternal,
    [`pagination--${this.size}`]: !!this.size
  })

  private getClassesNumberActive = (): ClassInfo => ({
    [`button-active`]: true,
    [`button-active--disabled`]: this.disabledInternal
  })

  private getClassesNumber = (): ClassInfo => ({
    [`number`]: true,
    [`number--disabled`]: this.disabledInternal
  })

  private getClassesDropdown = (): ClassInfo => ({
    [`dropdown`]: true,
    [`dropdown--disabled`]: this.disabledInternal
  })

  private getClassesResult = (): ClassInfo => ({
    [`result--${this.size}`]: !!this.size,
    [`result--disabled`]: this.disabledInternal
  })

  private getClassesSelect = (): ClassInfo => ({
    [`select`]: true,
    [`select--${this.size}`]: !!this.size,
    [`select--disabled`]: this.disabledInternal
  })

  private _dispatchCustomEvent(page: number): void {
    dispatchCustomEvent({
      eventName: ClbPagination.events.onClbChangePage,
      eventOptions: {
        detail: {
          currentPage: page,
          itemsPerPage: this.itemsPerPage,
          totalPage: this.totalPages
        }
      },
      dispatcher: this
    })
  }

  private updateTotalPages(data: number, dataPage: number) {
    this.totalPages = Math.ceil(data / dataPage)
    if (this.totalPages < 0) {
      this.totalPages = 0
    }

    if (this.totalPages < this.currentPage) {
      this.currentPage = 0
    }
  }

  private nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++
    }
  }

  private prevPage() {
    if (this.currentPage > 0) {
      this.currentPage--
    }
  }

  private goToPage(page: number) {
    this.currentPage = page
  }

  private _eventValueSelect(e: any) {
    const value = parseInt(e.detail.value)

    dispatchCustomEvent({
      eventName: ClbPagination.events.onClbSelectChange,
      eventOptions: { detail: { value: value } },
      dispatcher: this
    })

    if (this.totalItems > 0) {
      this.totalPages = Math.ceil(this.totalItems / value)
    }

    this.itemsPerPage = value
    this.currentPage = 0
  }

  private browsePages(iconType: string) {
    if (iconType === 'first') {
      this.goToPage(0)
    } else if (iconType === 'last') {
      this.goToPage(this.totalPages - 1)
    } else if (iconType === 'next') {
      this.nextPage()
    } else if (iconType === 'prev') {
      this.prevPage()
    }
  }

  private numbersInterval() {
    let maxLeft
    let maxRight
    const lastPageControl = this.totalPages - 5

    if (this.size == 'lg') {
      this.visibleButtons = 3
      if (this.totalPages <= 10) {
        maxRight = this.totalPages - 1
        maxLeft = 0
      } else if (this.currentPage <= 4) {
        maxLeft = 0
        maxRight = 4
      } else if (this.currentPage >= this.totalPages - 3) {
        maxLeft = lastPageControl
        maxRight = this.totalPages - 1
      } else {
        maxLeft = this.currentPage - Math.floor(this.visibleButtons / 2)
        maxRight = this.currentPage + Math.floor(this.visibleButtons / 2)
      }
    } else {
      this.visibleButtons = 2
      maxLeft = this.currentPage - 1
      maxRight = this.currentPage + 1
    }

    if (maxLeft < 0) {
      maxLeft = 0
      maxRight = this.visibleButtons
    }

    if (maxRight >= this.totalPages) {
      maxRight = this.totalPages - 1
      if (this.totalPages >= 3) {
        maxLeft = maxLeft - 1
      }
    }
    return { maxLeft, maxRight }
  }

  private renderNumbers(maxLeft: number, maxRight: number) {
    const numberPage: any = []
    for (let page = maxLeft; page <= maxRight; page++) {
      if (this.currentPage == page) {
        numberPage.push(
          html`<button
            id=${page}
            @click=${() => this._dispatchCustomEvent(page)}
            class="${classMap(this.getClassesNumberActive())}"
          >
            ${page + 1}
          </button>`
        )
      } else {
        numberPage.push(
          html`<button
            id=${page}
            @click=${() => [
              this._dispatchCustomEvent(page),
              this.goToPage(page)
            ]}
            class="${classMap(this.getClassesNumber())}"
          >
            ${page + 1}
          </button>`
        )
      }
    }

    return numberPage
  }

  private numbersDropdownInterval(max: number, direction: string) {
    const interval = []

    if (direction == 'right') {
      for (let page = max + 1; page < this.totalPages - 1; page++) {
        interval.push(html` <button
          id=${page}
          class="button-dropdown--right"
          slot="dropdown-content"
          @click=${() => [this._dispatchCustomEvent(page), this.goToPage(page)]}
        >
          ${page + 1}
        </button>`)
      }
      return interval
    }

    for (let page = max - 1; page > 0; page--) {
      interval.push(html` <button
        id=${page}
        class="button-dropdown--left"
        slot="dropdown-content"
        @click=${() => [this._dispatchCustomEvent(page), this.goToPage(page)]}
      >
        ${page + 1}
      </button>`)
    }
    return interval
  }

  private renderDropdown(max: number, direction: string) {
    const dropdown = html` <clb-dropdown
      type="default"
      ?open="false"
      width="60px"
    >
      <clb-button-icon
        class="${classMap(this.getClassesDropdown())}"
        slot="dropdown-trigger"
        icon="More"
        size="sm"
        ?disabled=${this.disabledInternal}
      ></clb-button-icon>
      ${this.numbersDropdownInterval(max, direction)}
    </clb-dropdown>`

    return dropdown
  }

  private updateNumbers() {
    const { maxLeft, maxRight } = this.numbersInterval()
    let numberPage = []
    const firsPage = 0
    const lastPage = this.totalPages - 1

    if (this.size == 'lg' && this.totalPages > 10) {
      if (maxLeft == 0) {
        numberPage = this.renderNumbers(maxLeft, maxLeft + 4)
        numberPage.push(this.renderDropdown(maxRight, 'right'))
        numberPage.push(this.renderNumbers(lastPage, lastPage))
      } else if (maxRight == lastPage) {
        numberPage.push(this.renderNumbers(firsPage, firsPage))
        numberPage.push(this.renderDropdown(maxLeft, 'left'))
        numberPage.push(this.renderNumbers(maxRight - 4, maxRight))
      } else {
        numberPage.push(this.renderNumbers(firsPage, firsPage))
        numberPage.push(this.renderDropdown(maxLeft, 'left'))
        numberPage.push(this.renderNumbers(maxLeft, maxRight))
        numberPage.push(this.renderDropdown(maxRight, 'right'))
        numberPage.push(this.renderNumbers(lastPage, lastPage))
      }
      return numberPage
    }

    numberPage.push(this.renderNumbers(maxLeft, maxRight))

    return numberPage
  }

  private renderResultQuantity() {
    const page = this.currentPage
    let start = page * this.itemsPerPage
    let end = start + this.itemsPerPage

    if (this.totalItems < 0) end = 0
    if (end > this.totalItems) end = this.totalItems
    if (start >= this.totalItems) start = this.totalItems - 1

    return html`<clb-text ?disabled=${this.disabledInternal}>
      ${start + 1}-${end} de ${this.totalItems} resultados
    </clb-text>`
  }

  private uptadeSize(lastWidth) {
    if (window.innerWidth !== lastWidth) {
      if (window.innerWidth <= 767) {
        this.size = 'sm'
      } else if (768 <= window.innerWidth && window.innerWidth <= 1024) {
        this.size = 'md'
      } else {
        this.size = 'lg'
      }
      this.lastWidth = window.innerWidth
    }
  }

  private handleUptadeWindow(): any {
    const lastWidth = this.lastWidth
    window.addEventListener('resize', () => this.uptadeSize(lastWidth))
  }

  private renderSelect() {
    return html` <clb-select
        id="select"
        name="select-name"
        label="select"
        .value="${this.itemsPerPage.toString()}"
        size="sm"
        ?showMessage=${false}
        ?showHelperText=${false}
        ?required=${false}
        ?disabled=${this.disabledInternal}
        ?error=${false}
        .maxHeight=${10}
        .options=${this.setValuesSelect()}
        @onClbChange=${(e) => this._eventValueSelect(e)}
      >
      </clb-select>

      <clb-text ?disabled=${this.disabledInternal}>Linhas por página</clb-text>`
  }

  private renderResult() {
    if (this.showResult) {
      return html` <div class="${classMap(this.getClassesResult())}">
        ${this.renderResultQuantity()}
      </div>`
    }
  }

  renderChevronDoubleLeft() {
    if (this.totalPages > 3 && this.size != 'lg') {
      return html` <clb-button-icon
        icon="ChevronDoubleLeft"
        size="sm"
        ?disabled="${this.disabledInternal || this.currentPage === 0}"
        @click=${() =>
          when(!this.disabledInternal, () => [
            this._dispatchCustomEvent(0),
            this.browsePages('first')
          ])}
      ></clb-button-icon>`
    }
  }

  renderChevronDoubleRight() {
    if (this.totalPages > 3 && this.size != 'lg') {
      return html` <clb-button-icon
        icon="ChevronDoubleRight"
        size="sm"
        ?disabled="${this.disabledInternal ||
        this.currentPage === this.totalPages - 1}"
        @click=${() =>
          when(!this.disabledInternal, () => [
            this.browsePages('last'),
            this._dispatchCustomEvent(this.totalPages - 1)
          ])}
      ></clb-button-icon>`
    }
  }

  protected updated(changedProps) {
    if (
      (changedProps.has('totalItems') || changedProps.has('disabled')) &&
      this.control
    ) {
      this.updateTotalPages(this.totalItems, this.itemsPerPage)
      if (this.totalItems <= 0 && this.totalPages <= 0) {
        this.disabledInternal = true
      } else {
        this.disabledInternal = false
      }
    }

    this.disabledInternal = this.disabled || this.disabledInternal

    this.handleUptadeWindow()
  }

  protected firstUpdated(): void {
    if (this.totalItems == 0 && this.totalPages != 0) {
      this.control = false
    }
  }

  render() {
    return html` <div
      class="${classMap(this.getClassesPagination())}"
      tabindex="0"
    >
      <div class="${classMap(this.getClassesSelect())}">
        ${this.renderSelect()}
      </div>
      <div class="numbers">
        ${this.renderChevronDoubleLeft()}
        <clb-button-icon
          icon="ChevronLeft"
          size="sm"
          ?disabled="${this.disabledInternal || this.currentPage === 0}"
          @click=${() =>
            this.disabledInternal
              ? undefined
              : [
                  this._dispatchCustomEvent(this.currentPage - 1),
                  this.browsePages('prev')
                ]}
        ></clb-button-icon>
        <div class="numbers">${this.updateNumbers()}</div>
        <clb-button-icon
          icon="ChevronRight"
          size="sm"
          ?disabled="${this.disabledInternal ||
          this.currentPage === this.totalPages - 1}"
          @click=${() =>
            this.disabledInternal
              ? undefined
              : [
                  this._dispatchCustomEvent(this.currentPage + 1),
                  this.browsePages('next')
                ]}
        ></clb-button-icon>
        ${this.renderChevronDoubleRight()}
      </div>
      ${this.renderResult()}
    </div>`
  }
}
