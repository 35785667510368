import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'
import { when } from 'lit/directives/when.js'

import { ClbMixin } from '@utils/ClbMixin'
import { dispatchCustomEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import '@components/icon'

import { typeDay } from './props.types'
import styles from './styles.scss'

@registerElement('clb-calendar-day')
export class ClbCalendarDay extends ClbMixin(LitElement) {
  @property({ type: String }) day = '1'
  @property({ type: String }) selectedDate = ''
  @property({ type: Boolean }) selected = false
  @property({ type: Boolean }) isToday = false
  @property({ type: String }) typeDay: typeDay = 'in-current-month'
  @property({ type: String }) tooltipLabel = ''

  static styles = [styles]

  static events = {
    onClbSelectDay: 'onClbSelectDay'
  }

  private getDayClasses = (): ClassInfo => ({
    [`day`]: true,
    [`day-selected`]: this.selected,
    [`day-today`]: this.isToday,
    [`day--${this.typeDay}`]: true
  })

  private _bubbleEvent(): void {
    dispatchCustomEvent({
      eventName: ClbCalendarDay.events.onClbSelectDay,
      eventOptions: {
        cancelable: true,
        detail: {
          day: this.day,
          selected: this.selected,
          typeDay: this.typeDay
        }
      },
      dispatcher: this
    })
  }

  render() {
    return html`
      ${when(
        this.isToday,
        () => html`<clb-tooltip label="${this.tooltipLabel}">
          <p
            id="${this.typeDay === 'in-current-month'
              ? `day${this.day}`
              : `day${this.day}-${this.typeDay}`}"
            class="${classMap(this.getDayClasses())}"
            @click="${this._bubbleEvent}"
          >
            ${this.day}
          </p>
        </clb-tooltip>`,
        () => html`<p
          id="${this.typeDay === 'in-current-month'
            ? `day${this.day}`
            : `day${this.day}-${this.typeDay}`}"
          class="${classMap(this.getDayClasses())}"
          @click="${this._bubbleEvent}"
        >
          ${this.day}
        </p>`
      )}
    `
  }
}
