import { html, LitElement } from 'lit'

import { ClbTableTBodyRow } from '@components/table-tbody-row'
import { ClbTableTHeadCell } from '@components/table-thead-cell'
import { ClbTableTHeadRow } from '@components/table-thead-row'

import '@components/icon'
import '@components/button-icon'
import '@components/avatar'
import '@components/tag'
import '@components/link'
import '@components/checkbox'
import '@components/input-search'
import '@components/button-group'

import { ClbMixin } from '@utils/ClbMixin'
import { bubbleEvent, createEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-table')
export class ClbTable extends ClbMixin(LitElement) {
  static styles = [styles]

  static events = {
    onClbTableSortChange: 'onClbTableSortChange'
  }

  get _headerRow(): ClbTableTHeadRow {
    return this.querySelector('clb-table-thead-row')
  }

  get _headerCells(): NodeListOf<ClbTableTHeadCell> {
    return this._headerRow.querySelectorAll('clb-table-thead-cell')
  }

  get _rows(): NodeListOf<ClbTableTBodyRow> {
    return this.querySelectorAll('clb-table-tbody-row')
  }

  connectedCallback(): void {
    super.connectedCallback()
    this.addEventListener(
      ClbTableTHeadCell.events.onClbSortChange,
      this._handleSortChange
    )
    this.addEventListener(
      ClbTableTHeadRow.events.onClbSelectTheadRow,
      this._handleCheckAll
    )
    this.addEventListener(
      ClbTableTBodyRow.events.onClbSelectRow,
      this._handleCheck
    )
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    this.removeEventListener(
      ClbTableTHeadCell.events.onClbSortChange,
      this._handleSortChange
    )
    this.removeEventListener(
      ClbTableTHeadRow.events.onClbSelectTheadRow,
      this._handleCheckAll
    )
    this.removeEventListener(
      ClbTableTBodyRow.events.onClbSelectRow,
      this._handleCheck
    )
  }

  _handleSortChange = (evt: CustomEvent) => {
    const event = createEvent<{
      id: string
      direction: 'asc' | 'desc' | undefined
    }>(ClbTable.events.onClbTableSortChange, {
      ...evt,
      composed: true,
      bubbles: true,
      cancelable: true,
      detail: evt.detail
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)
    if (this.dispatchEvent(event)) {
      this._headerCells.forEach((cell) => {
        if (cell.id === event.detail.id) {
          cell.sortDirection = event.detail.direction
        }
        cell.sortDirection = undefined
      })

      this.requestUpdate()
    } else {
      evt.preventDefault()
    }
  }

  _handleCheckAll = (e: CustomEvent) => {
    this._rows.forEach((row) => {
      row.checked = e.detail.checked
    })
  }

  _handleCheck = (e: CustomEvent) => {
    this._rows.forEach((row) => {
      if (e.detail.id === row.id) {
        row.checked = e.detail.checked
      }
    })

    const rows = [...this._rows]

    if (rows.every((item) => item.checked)) {
      this._headerRow.checked = true
      this._headerRow.indeterminate = false
    } else if (rows.every((item) => !item.checked)) {
      this._headerRow.checked = false
      this._headerRow.indeterminate = false
    } else {
      this._headerRow.checked = false
      this._headerRow.indeterminate = true
    }
    this.requestUpdate()
  }

  render() {
    return html` <slot></slot> `
  }
}
