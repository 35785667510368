import { ClbLink } from '@components/link/props.types'
export const typesArray = ['positive', 'negative', 'warning'] as const

export const typesIconArray = ['Spam', 'Warning', 'Check'] as const

export type Types = typeof typesArray[number]
export type TypesIcon = typeof typesIconArray[number]

export type ClbLinkPros = {
  title: string
} & ClbLink

export interface ToastBaseArgTypes {
  type: Types
  title: string
  description?: string
  link?: ClbLink
  open: boolean
}
