import { html, LitElement, PropertyValues } from 'lit'
import { property } from 'lit/decorators.js'
import { when } from 'lit/directives/when.js'

import '@components/button-icon'
import '@components/floating-menu'
import styles from '@components/table/styles.scss'

import { ClbMixin } from '@utils/ClbMixin'
import { bubbleEvent, createEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

const _MIN_WIDTH = 50

@registerElement('clb-table-thead-cell')
export class ClbTableTHeadCell extends ClbMixin(LitElement) {
  @property({ type: String }) id = ''
  @property({ type: Boolean }) filterable = false
  @property({ type: Boolean }) sortable = false
  @property({ type: String }) sortDirection?: 'asc' | 'desc'
  @property({ type: Number }) minWidthCol = _MIN_WIDTH

  static styles = [styles]

  static slots = {
    filter: 'filter',
    content: 'content'
  }

  static events = {
    onClbSortChange: 'onClbSortChange'
  }

  protected override updated(changedProperties: PropertyValues): void {
    if (changedProperties.get('minWidthCol') !== undefined) {
      this._defineMinWidthCell()
    }
  }

  protected firstUpdated(): void {
    this._defineMinWidthCell()
  }

  _defineMinWidthCell() {
    const table = this.shadowRoot.querySelector(
      '.thead-wrapper'
    ) as HTMLDivElement
    table.style.minWidth =
      this.minWidthCol > _MIN_WIDTH
        ? `${this.minWidthCol}px`
        : `${_MIN_WIDTH}px`
  }

  getSortIcon() {
    switch (this.sortDirection) {
      case 'asc':
        return 'Order'
      case 'desc':
        return 'Order1'
      default:
        return 'Order2'
    }
  }

  getNextSortDirection() {
    switch (this.sortDirection) {
      case 'asc':
        return 'desc'
      case 'desc':
        return undefined
      default:
        return 'asc'
    }
  }

  handleSortChange(evt: MouseEvent) {
    const newDirection = this.getNextSortDirection()
    const event = createEvent(ClbTableTHeadCell.events.onClbSortChange, {
      bubbles: true,
      cancelable: true,
      composed: true,
      detail: { id: this.id, direction: newDirection }
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)

    if (this.dispatchEvent(event)) {
      this.sortDirection = newDirection
    }
  }

  renderFilterButton = () => {
    return html`
      <clb-floating-menu>
        <clb-button-icon size="sm" icon="Filter" slot="floating-menu-trigger">
        </clb-button-icon>
        <div slot="floating-menu-content">
          <slot name="filter"></slot>
        </div>
      </clb-floating-menu>
    `
  }

  renderSortButton = () => {
    return html`<clb-button-icon
      @click=${this.handleSortChange}
      size="sm"
      icon="${this.getSortIcon()}"
    >
    </clb-button-icon>`
  }

  render() {
    return html`
      <div class="thead-wrapper">
        <slot name="content"></slot>
        ${when(this.filterable, this.renderFilterButton)}
        ${when(this.sortable, this.renderSortButton)}
      </div>
    `
  }
}
