export enum regexValiratorsMasksEnum {
  CPF = '000.000.000-00',
  CNPJ = '00.000.000/0000-00',
  CEP = '00000-000',
  CELULAR = '(00)00000-0000',
  NONE = '00000000000'
}

export enum regexValidatorsEnum {
  CPF = '^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$',
  CPFclean = '^[0-9]{3}[0-9]{3}[0-9]{3}[0-9]{2}$',
  CPFfilter = '^[0-9.-]*',
  CEP = '^[0-9]{5}-[0-9]{3}$',
  CEPclean = '^[0-9]{5}[0-9]{3}$',
  CEPfilter = '^[0-9-]*',
  CNPJ = '^[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}$',
  CNPJclean = '^[0-9]{2}[0-9]{3}[0-9]{3}[0-9]{4}[0-9]{2}$',
  CNPJfilter = '^[0-9./-]*',
  CELULAR = '^\\([0-9]{2}\\)[0-9]{5}-[0-9]{4}$',
  CELULARclean = '^[0-9]{2}[0-9]{5}[0-9]{4}$',
  CELULARfilter = '^[0-9-()]*',
  NONE = '^[0-9{9}]$',
  NONEclean = '^[0-9{9}]$',
  NONEfilter = '^[0-9{9}]*'
}

export const regexValidatorsList = [
  'NONE',
  'CPF',
  'CNPJ',
  'CEP',
  'CELULAR'
] as const
export type RegexValidators = typeof regexValidatorsList[number]
