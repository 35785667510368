import {css} from 'lit';
export const styles = css`.heading {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-semibold);
  font-style: normal;
  color: var(--color-neutral-600);
  letter-spacing: normal;
  text-align: left;
  margin: 0;
  padding: 0;
}
.heading--display {
  font-size: var(--font-size-7xl);
  line-height: var(--line-height-default);
}
@media only screen and (max-width: 767px) {
  .heading--display {
    font-size: var(--font-size-5xl);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1365px) {
  .heading--display {
    font-size: var(--font-size-5xl);
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .heading--display {
    font-size: var(--font-size-7xl);
  }
}
@media only screen and (min-width: 1440px) {
  .heading--display {
    font-size: var(--font-size-7xl);
  }
}
.heading--2xl {
  font-size: var(--font-size-5xl);
  line-height: var(--line-height-default);
}
@media only screen and (max-width: 767px) {
  .heading--2xl {
    font-size: var(--font-size-4xl);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1365px) {
  .heading--2xl {
    font-size: var(--font-size-4xl);
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .heading--2xl {
    font-size: var(--font-size-5xl);
  }
}
@media only screen and (min-width: 1440px) {
  .heading--2xl {
    font-size: var(--font-size-5xl);
  }
}
.heading--xl {
  font-size: var(--font-size-4xl);
  line-height: var(--line-height-xs);
}
@media only screen and (max-width: 767px) {
  .heading--xl {
    font-size: var(--font-size-3xl);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1365px) {
  .heading--xl {
    font-size: var(--font-size-3xl);
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .heading--xl {
    font-size: var(--font-size-4xl);
  }
}
@media only screen and (min-width: 1440px) {
  .heading--xl {
    font-size: var(--font-size-4xl);
  }
}
.heading--lg {
  font-size: var(--font-size-2xl);
  line-height: var(--line-height-xs);
}
.heading--sm {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-md);
}
.heading--xs {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-md);
}
.heading--2xs {
  font-size: var(--font-size-default);
  line-height: var(--line-height-md);
}`;
export default styles;
