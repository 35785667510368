import { html, LitElement } from 'lit'
import { property, queryAssignedElements } from 'lit/decorators.js'
import { when } from 'lit/directives/when.js'

import '@components/button-icon'

import { ClbMixin } from '@utils/ClbMixin'
import { createEvent, bubbleEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-floating-menu')
export class ClbFloatingMenu extends ClbMixin(LitElement) {
  @property({ type: Boolean }) open = false

  @queryAssignedElements({ slot: ClbFloatingMenu.slots.content, flatten: true })
  _contentElements: Array<LitElement & { open?: boolean }>

  static events = {
    onVisibilityChange: 'onVisibilityChange'
  }

  static slots = {
    trigger: 'floating-menu-trigger',
    content: 'floating-menu-content'
  }

  static styles = [styles]

  protected firstUpdated(): void {
    this._handlePosition()
  }

  protected updated(): void {
    this._handlePosition()
  }

  connectedCallback(): void {
    super.connectedCallback()
    window.addEventListener('click', this._handleClickOutside)
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    window.removeEventListener('click', this._handleClickOutside)
  }

  _handleClickOutside = (event: MouseEvent) => {
    const path = event.composedPath()
    if (this.open && !path.includes(this)) {
      this._toggleVisibility(event, false)
    }
  }

  _toggleVisibility = (evt: Event, newState = !this.open) => {
    const event = createEvent(ClbFloatingMenu.events.onVisibilityChange, {
      cancelable: true,
      detail: { open: newState }
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)

    if (this.dispatchEvent(event)) {
      this.open = newState
      if (this._contentElements[0]) {
        this._contentElements[0].open = newState
      }
    }
  }

  _handleTriggerClick = (event: Event) => {
    this._toggleVisibility(event)
  }

  get root(): any {
    return this.shadowRoot || this
  }

  _handlePosition() {
    const floatingTriggerElement: HTMLElement =
      this.root.querySelector('#floating-trigger')

    const floatingElement: HTMLElement = this.root.querySelector('#floating')

    const newPosition = {
      left: undefined,
      top: undefined,
      right: undefined,
      bottom: undefined
    }

    if (!floatingTriggerElement && !floatingElement) {
      this.open = false
      Object.entries(newPosition).forEach(([key]) => {
        floatingElement.style.setProperty(key, 'unset')
      })
      return
    }

    const {
      height: triggerHeight,
      width: triggerWidth,
      bottom: triggerBottom,
      right: triggerRight
    } = floatingTriggerElement.getBoundingClientRect()

    const { height: floatingHeight, width: floatingWidth } =
      floatingElement.getBoundingClientRect()

    const { innerWidth: windowWidth, innerHeight: windowHeight } = window

    const marginOffset = 5

    const contentHeight = floatingHeight + marginOffset

    if (windowHeight < triggerBottom + contentHeight) {
      newPosition.top = undefined
      newPosition.bottom = triggerHeight + marginOffset
    } else {
      newPosition.top = triggerHeight + marginOffset
      newPosition.bottom = undefined
    }

    if (windowWidth < triggerRight + floatingWidth) {
      newPosition.left = -floatingWidth + triggerWidth
      newPosition.right = undefined
    } else {
      newPosition.left = 0
      newPosition.right = undefined
    }

    Object.entries(newPosition).forEach(([key, value]) => {
      floatingElement.style.setProperty(
        key,
        value === undefined ? 'unset' : `${value}px`
      )
    })
  }

  renderMenuList() {
    return when(
      this.open,
      () => html`<div class="floating__list">
        <div class="floating__content" id="floatingList">
          <slot
            name=${ClbFloatingMenu.slots.content}
            @onslotchange=${this._handlePosition}
          >
          </slot>
        </div>
      </div> `
    )
  }

  render() {
    return html`
      <div class="floating">
        <div id="floating-trigger">
          <slot
            name=${ClbFloatingMenu.slots.trigger}
            @click=${this._handleTriggerClick}
            @onslotchange=${this._handlePosition}
          >
            <clb-button-icon size="sm" icon="More"></clb-button-icon>
          </slot>
        </div>
        <div id="floating" class="floating__container">
          ${this.renderMenuList()}
        </div>
      </div>
    `
  }
}
