import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'

import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

import { Sizes, Colors, Tones } from './props.types'
import styles from './style.scss'

@registerElement('clb-text')
export class ClbText extends ClbMixin(LitElement) {
  @property({ type: String }) size: Sizes = 'md'
  @property({ type: String }) color: Colors = 'neutral'
  @property({ type: String }) tone: Tones
  @property({ type: Boolean }) disabled = false

  static styles = [styles]

  render() {
    return html`
      <p
        class="text ${classMap({
          [`text--${this.size}`]: this.size,
          ['text--disabled']: this.disabled,
          [`text--${this.color}`]: this.color,
          [`text--${this.color}--${this.tone}`]: !!this.color && !!this.tone
        })}"
      >
        <slot></slot>
      </p>
    `
  }
}
