import {css} from 'lit';
export const styles = css`:host([blockedWidth]) {
  display: block;
  width: 100%;
}

.button {
  height: 48px;
  min-width: 160px;
  padding: 0 var(--spacing-size-2xs);
  border: 0;
  border-radius: var(--border-radius-default);
  background-color: var(--color-action-default);
  cursor: pointer;
  font-family: var(--font-family-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-default);
  color: var(--color-contrast-brand);
}
.button-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.button-loading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: progress;
}
.button-blockedWidth {
  width: 100%;
}
.button--sm {
  height: 36px;
}
.button--md {
  height: 48px;
}
.button--lg {
  height: 64px;
}
.button--blocked {
  width: 100%;
}
.button--secondary {
  background-color: var(--color-opacity-default);
  border: var(--border-width-default) solid var(--color-neutral-600);
  color: var(--color-neutral-600);
}
.button--secondary:disabled, .button--secondary:hover {
  border: transparent;
  color: var(--color-contrast-brand);
}
.button--tertiary {
  background-color: transparent;
  border: 0;
  color: var(--color-neutral-600);
}
.button--tertiary:disabled, .button--tertiary:hover {
  border: transparent;
  color: var(--color-contrast-brand);
}
.button--danger {
  background-color: var(--color-feedback-danger-300);
  border: 0;
  color: var(--color-contrast-white);
}
.button--danger:disabled {
  border: transparent;
}
.button--danger:hover {
  border: transparent;
  background-color: var(--color-feedback-danger-600) !important;
}
.button:hover {
  background-color: var(--color-action-hover);
}
.button:focus-visible {
  outline: 2px solid var(--color-contrast-focused);
  outline-offset: 2px;
}
.button:disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: var(--color-neutral-200);
  color: var(--color-neutral-300);
}
.button:active {
  opacity: var(--opacity-level-1);
}`;
export default styles;
