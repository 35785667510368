import {css} from 'lit';
export const styles = css`.floating {
  position: relative;
}
.floating__list {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.floating__container {
  position: absolute;
  z-index: 20;
}
.floating__content {
  max-height: 288px;
  overflow-y: auto;
}`;
export default styles;
