import { html, LitElement, PropertyValues } from 'lit'
import { property } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'

import { ButtonAttributes } from '@components/button-group/props.types'
import '@components/heading'
import '@components/button-group'
import '@components/button-icon'
import '@components/progress-bar'

import { ClbMixin } from '@utils/ClbMixin'
import { createEvent, bubbleEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-full-page-flow')
export class ClbFullPageFlow extends ClbMixin(LitElement) {
  @property({ type: String }) title = ''

  @property({ type: String }) type = 'default'

  @property({ type: Number }) steps = 0

  @property({ type: Number }) activeStep = 0

  @property({ type: Boolean }) opened = false

  @property({ type: Array }) buttonsList: Array<ButtonAttributes> = []

  @property({ attribute: 'aria-labelledby', type: String }) ariaLabelledBy = ''

  @property({ attribute: 'aria-label', type: String }) ariaLabel = 'texto'

  static styles = [styles]

  static events = {
    onClbClose: 'onClbClose',
    onClbHaveOpened: 'onClbHaveOpened',
    onClbHaveClosed: 'onClbHaveClosed'
  }

  protected override updated(changedProperties: PropertyValues): void {
    if (changedProperties.get('opened') !== undefined) {
      this._emitVisibilityChange()
    }
  }

  protected firstUpdated() {
    this._bodyOverflowChange()
  }

  _bodyOverflowChange() {
    const body = document.querySelector('body')
    if (this.opened) {
      window.scrollTo(0, 0)
      body.style.overflow = 'hidden'
      return
    }
    body.style.overflow = 'auto'
  }

  private _emitVisibilityChange() {
    this._bodyOverflowChange()

    const details = {
      bubbles: true,
      composed: true
    }
    if (this.opened) {
      this.dispatchEvent(
        new CustomEvent(ClbFullPageFlow.events.onClbHaveOpened, details)
      )
    } else {
      this.dispatchEvent(
        new CustomEvent(ClbFullPageFlow.events.onClbHaveClosed, details)
      )
    }
  }

  private getClassAttributes = (): ClassInfo => ({
    ['full-page-flow']: true,
    ['full-page-flow--opened']: this.opened,
    ['full-page-flow--closed']: !this.opened
  })

  private getClassContainerSlotAttributes = (): ClassInfo => ({
    ['full-page-flow__slot__container']: true,
    ['full-page-flow__slot__container-progress']: this.type === 'progress'
  })

  _handleClick(evt: Event) {
    const event = createEvent(ClbFullPageFlow.events.onClbClose, {
      cancelable: true
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)
    this.dispatchEvent(event)
  }

  _calculatePercentage() {
    if (this.steps > 0) {
      return (this.activeStep * 100) / this.steps
    }
    return 0
  }

  render() {
    return html`
      <div
        role="dialog"
        aria-modal=${this.opened}
        tabindex=${this.tabIndex}
        class=${classMap(this.getClassAttributes())}
        aria-label=${this.ariaLabel}
        aria-labelledby=${this.ariaLabelledBy}
      >
        <div class="full-page-flow__relative">
          <div class="full-page-flow__header">
            <clb-heading class="full-page-flow__heading" size="xs">
              ${this.title}
            </clb-heading>
            <div class="full-page-flow__buttons">
              <clb-button-group
                type="simple"
                size="md"
                .buttonsList=${this.buttonsList}
              ></clb-button-group>
              <clb-button-icon
                class="full-page-flow__close"
                icon="Close"
                size="lg"
                @click=${this._handleClick}
              >
              </clb-button-icon>
            </div>
            <div
              class=${this.type === 'progress'
                ? 'full-page-flow__progress-bar--show'
                : 'full-page-flow__progress-bar--hide'}
            >
              <clb-progress-bar value=${this._calculatePercentage()}>
              </clb-progress-bar>
            </div>
          </div>
          <div class=${classMap(this.getClassContainerSlotAttributes())}>
            <slot></slot>
          </div>
        </div>
      </div>
    `
  }
}
