import { html, LitElement } from 'lit'
import { property, state } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'
import { when } from 'lit/directives/when.js'

import '@components/icon/'
import '@components/loading/'

import { Icons, Types } from '@components/icon/props.types'
import * as LoadingPropsTypes from '@components/loading/props.types'

import { ClbMixin } from '@utils/ClbMixin'
import { bubbleEvent, createEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import { IconPositionType, ButtonType, Sizes, StyleType } from './props.types'
import styles from './styles.scss'

@registerElement('clb-button')
export class ClbButton extends ClbMixin(LitElement) {
  @property({ type: String }) styleType: StyleType = 'primary'

  @property({ type: String }) buttonType: ButtonType = 'button'

  @property({ type: String }) iconPosition: IconPositionType = 'none'

  @property({ type: String }) size: Sizes = 'md'

  @property({ type: Boolean }) disabled = false

  @property({ type: Boolean }) loading = false

  @property({ type: String }) icon: Icons

  @property({ type: Boolean }) blockedWidth = false

  @property({ attribute: 'aria-label', type: String }) ariaLabel = ''

  @state() iconType: Types = 'neutral'

  private loadingTypes: Record<string, LoadingPropsTypes.Types> = {
    primary: 'brand',
    secondary: 'default',
    tertiary: 'default',
    danger: 'danger'
  }

  static styles = [styles]

  static events = {
    onClbClick: 'onClbClick'
  }

  private get shouldRenderIcon(): boolean {
    return (
      this.icon && ['primary', 'secondary', 'tertiary'].includes(this.styleType)
    )
  }

  private get loadingType(): LoadingPropsTypes.Types {
    return this.loadingTypes[this.styleType]
  }

  private getClassAttributes = (): ClassInfo => ({
    [`button`]: true,
    [`button--${this.styleType}`]: this.styleType,
    [`button--${this.size}`]: this.size,
    [`button-blockedWidth`]: this.blockedWidth,
    [`button-loading`]: this.loading,
    [`button-icon`]: this.shouldRenderIcon || this.styleType
  })

  private _bubbleEvent = (evt: Event): void => {
    if (this.loading) {
      return
    }
    const event = createEvent(ClbButton.events.onClbClick, {
      cancelable: true
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)
    this._submitFormIfNeeded(event)
  }

  private _submitFormIfNeeded = (event: Event): void => {
    const formElement = this.closest('form')
    if (
      formElement &&
      this.styleType === 'primary' &&
      event.type === ClbButton.events.onClbClick &&
      this.buttonType === 'submit'
    ) {
      formElement.requestSubmit()
    }
    this.dispatchEvent(event)
  }

  private get _renderButtonByType() {
    return html` ${when(
      this.styleType !== 'danger' && !this.loading,
      () => html`${this._defineIconPosition}`
    )}
    ${when(
      this.loading,
      () => html`
        <clb-loading size="sm" type="${this.loadingType}"></clb-loading>
      `
    )}
    ${when(
      this.styleType === 'danger' && !this.loading,
      () => html`
        <clb-icon
          id="icon"
          icon="${this.icon || 'Cancel'}"
          type="withe"
          size="md"
          ?baseIcon=${true}
        ></clb-icon>
        <slot></slot>
      `
    )}`
  }

  private _defineIconStyle() {
    this.iconType = 'hover'
    if (this.disabled) this.iconType = 'disabled'
  }

  private get _defineIconPosition() {
    this._defineIconStyle()
    return html`
      ${when(this.iconPosition === 'none', () => html` <slot></slot>`)}
      ${when(
        this.iconPosition === 'left',
        () =>
          html`<clb-icon
              size="md"
              icon=${this.icon}
              type=${this.iconType}
              ?baseIcon=${true}
              id="teste"
            ></clb-icon>
            <slot></slot>`
      )}
      ${when(
        this.iconPosition === 'right',
        () =>
          html`<slot></slot>
            <clb-icon
              size="md"
              icon=${this.icon}
              type=${this.iconType}
              ?baseIcon=${true}
            ></clb-icon> `
      )}
    `
  }

  render() {
    return html`
      <button
        type="${this.buttonType}"
        part="button"
        class="${classMap(this.getClassAttributes())}"
        ?disabled=${this.loading ? false : this.disabled}
        @click=${this._bubbleEvent}
        role="${this.ariaLabel ? 'button' : undefined}"
        aria-label="${this.disabled && this.ariaLabel === ''
          ? 'botão desabilitado'
          : this.ariaLabel}"
      >
        ${this._renderButtonByType}
      </button>
    `
  }
}
