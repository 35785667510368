import {css} from 'lit';
export const styles = css`.toast {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 20;
  margin: 0;
  right: 0;
}
@media only screen and (min-width: 768px) {
  .toast {
    width: 398px;
    top: 1rem;
    right: 1rem;
    margin: var(--spacing-size-2xs);
  }
}`;
export default styles;
