import { html, LitElement } from 'lit'
import { property, query } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import { when } from 'lit/directives/when.js'

import { icons } from '@celebration/assets/dist'

import { Sizes } from '@components/input-base/props.types'

import { ClbMixin } from '@utils/ClbMixin'
import { bubbleEvent, dispatchCustomEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-calendar-input')
export class ClbCalendarInput extends ClbMixin(LitElement) {
  @property({ type: String }) label = 'Label'
  @property({ type: String }) placeholder = '00/00/0000'
  @property({ type: String }) value = ''
  @property({ type: String }) helperText = ''
  @property({ type: String }) requiredMessage = 'obrigatório'
  @property({ type: String }) optionalMessage = 'opcional'
  @property({ type: String }) name = ''
  @property({ type: Boolean }) showMessage = false
  @property({ type: Boolean }) showHelperText = false
  @property({ type: String }) size: Sizes = 'lg'
  @property({ type: Boolean }) required = false
  @property({ type: Boolean }) disabled = false
  @property({ type: Boolean }) error = false

  @query('input')
  input!: HTMLInputElement | null

  static styles = [styles]

  static events = {
    onClbChangeInputDate: 'onClbChangeInputDate',
    onClbClickInputDate: 'onClbClickInputDate',
    onClbClearInputDate: 'onClbClearInputDate'
  }

  private getLabelClasses = (): ClassInfo => ({
    [`input-label`]: true,
    [`input-label--disabled`]: this.disabled,
    [`input-label--${this.size}`]: true
  })

  private getInputClasses = (): ClassInfo => ({
    [`input-container`]: true,
    [`input-container--${this.size}`]: true,
    [`input-container--error`]: this.error,
    [`input-container--disabled`]: this.disabled
  })

  private getHelperClasses = (): ClassInfo => ({
    [`input-helper`]: true,
    [`input-label--disabled`]: this.disabled,
    [`input-helper--error`]: this.error && this.helperText
  })

  private inputMaskDate = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1')
  }

  private _bubbleEvent(evt: Event): void {
    const targetElement = this.shadowRoot.querySelector('input')

    bubbleEvent(evt, targetElement)

    if (evt.type === 'click') {
      const targetElement = this.shadowRoot.querySelector('#clear-input-date')
      targetElement.addEventListener('click', () => {
        dispatchCustomEvent({
          eventName: ClbCalendarInput.events.onClbClearInputDate,
          eventOptions: {
            detail: { value: '' }
          },
          targetElement,
          dispatcher: this
        })
      })
    }

    if (evt.type === 'input') {
      targetElement.addEventListener('input', (event) => {
        targetElement.value = this.inputMaskDate(
          (event.target as HTMLInputElement).value
        )
      })

      dispatchCustomEvent({
        eventName: ClbCalendarInput.events.onClbChangeInputDate,
        eventOptions: {
          detail: {
            value: targetElement.value
          }
        },
        targetElement,
        dispatcher: this
      })
    }

    targetElement.addEventListener('click', function (e) {
      dispatchCustomEvent({
        eventName: ClbCalendarInput.events.onClbClickInputDate,
        eventOptions: {
          detail: { value: targetElement?.value }
        },
        targetElement,
        dispatcher: targetElement
      })
      e.stopImmediatePropagation()
    })

    this._chooseDateTypingInput()
  }

  private _chooseDateTypingInput = () => {
    const splitValue = this.input.value.split('')
    const typedDay = splitValue[0] + splitValue[1]
    const typedMonth = splitValue[3] + splitValue[4]

    if (Number(typedDay) > 31 || Number(typedMonth) > 12) {
      this.error = true
    } else {
      this.error = false
    }
  }

  private _clearInputDate = (evt: Event) => {
    this.input.value = ''
    this.error = false
    this._bubbleEvent(evt)
  }

  render() {
    return html`<label
        for=${this.id}
        class="${classMap(this.getLabelClasses())}"
        tabindex="0"
      >
        ${this.label}
        ${when(
          this.required,
          () =>
            this.showMessage
              ? html`
                  <span class="input-label--message" tabindex="0">
                    (${this.requiredMessage})
                  </span>
                `
              : null,
          () =>
            this.showMessage
              ? html`
                  <span class="input-label--message" tabindex="0">
                    (${this.optionalMessage})
                  </span>
                `
              : null
        )}
      </label>
      <div class="${classMap(this.getInputClasses())}">
        ${this.disabled
          ? html`<clb-icon
              icon="Calendar"
              size="sm"
              type="disabled"
            ></clb-icon>`
          : html`<clb-icon icon="Calendar" size="sm"></clb-icon>`}
        <input
          id="${this.id}"
          type="text"
          class="${classMap(this.getInputClasses())}"
          placeholder="00/00/0000"
          aria-label="${this.value}"
          .value="${this.value}"
          name="${this.name}"
          ?disabled=${this.disabled}
          ?required=${this.required}
          @focus=${this._bubbleEvent}
          @blur=${this._bubbleEvent}
          @input=${this._bubbleEvent}
          @click=${this._bubbleEvent}
          @change=${this._bubbleEvent}
        />
        ${this.disabled
          ? html`<clb-icon
              icon="Close"
              type="disabled"
              size="sm"
              @click=${this._clearInputDate}
            ></clb-icon>`
          : html`<clb-icon
              id="clear-input-date"
              icon="Close"
              size="sm"
              @click=${this._clearInputDate}
            ></clb-icon>`}
      </div>
      ${when(
        this.showHelperText,
        () => html`
          <span class="${classMap(this.getHelperClasses())}" tabindex="0">
            <span>${unsafeSVG(icons['Spam'])}</span>
            ${this.helperText}
          </span>
        `,
        () => null
      )}`
  }
}
