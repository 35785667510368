import { html, LitElement } from 'lit'
import { property, state } from 'lit/decorators.js'

import { ClbMixin } from '@utils/ClbMixin'
import { createEvent, bubbleEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-radio-button')
export class ClbRadioButton extends ClbMixin(LitElement) {
  @property({ type: String }) name = ''

  @property({ type: String }) id = ''

  @property({ type: String }) value = ''

  @property({ type: Boolean }) checked = false

  @property({ type: Boolean }) disabled = false

  @property({ type: Boolean }) autofocus = false

  @property({ type: Boolean }) required = false

  @state()
  _checked = false

  static styles = [styles]

  static events = {
    onClbChange: 'onClbChange',
    onClbFocus: 'onClbFocus',
    onClbBlur: 'onClbBlur'
  }

  get inputElement() {
    return this.shadowRoot.querySelector('input')
  }

  get radios() {
    const radios = []
    const siblings = this.parentElement.children

    for (const sibling in siblings) {
      if (
        siblings[sibling].nodeName &&
        siblings[sibling].nodeName.toLocaleLowerCase() == 'clb-radio-button'
      ) {
        radios.push(siblings[sibling])
      }
    }

    return radios
  }

  handleChange(evt: Event) {
    const event = createEvent(ClbRadioButton.events.onClbChange, {
      ...evt,
      detail: { value: this.value }
    })
    bubbleEvent(evt, this.inputElement)
    if (this.dispatchEvent(event)) {
      this.check()
    }
  }

  handleFocus(evt: Event) {
    const event = createEvent(ClbRadioButton.events.onClbFocus, {
      ...evt,
      detail: { value: this.value }
    })
    bubbleEvent(event, this.inputElement)
  }

  handleBlur(evt: Event) {
    const event = createEvent(ClbRadioButton.events.onClbBlur, {
      ...evt,
      detail: { value: this.value }
    })
    bubbleEvent(event, this.inputElement)
  }

  check() {
    this.unmarkSiblings()
    this.checked = true
  }

  unmarkSiblings() {
    for (let index = 0; index < this.radios.length; index++) {
      const element = this.radios[index]
      if (!this.isSameNode(element)) {
        ;(element as HTMLInputElement).checked = false
      }
    }
  }

  get labelText() {
    const slotElement = this.shadowRoot.querySelector('slot') as HTMLSlotElement
    const childNodes = slotElement?.assignedNodes({ flatten: true })

    let label = ''

    for (let index = 0; index < childNodes?.length; index++) {
      const element = childNodes[index] as any

      if (element.data.indexOf('\n') == -1) {
        label = element.data
      }
    }

    return label
  }

  render() {
    return html`
      <div class="container">
        <label
          class="radio-label"
          disabled="${this.disabled}"
          for="${this.id || 'radio'}"
        >
          <input
            type="radio"
            class="radio-input"
            id="${this.id || 'radio'}"
            @focus=${this.handleFocus}
            @blur=${this.handleBlur}
            @input=${this.handleChange}
            .value="${this.value}"
            .checked="${this.checked}"
            name="${this.name}"
            ?autofocus=${this.autofocus}
            ?required=${this.required}
            ?disabled=${this.disabled}
            aria-label="${this.labelText || 'radio'}"
          />
          <span class="button-icon">
            <slot></slot>
          </span>
        </label>
      </div>
    `
  }
}
