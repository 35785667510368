import {css} from 'lit';
export const styles = css`.toast-base {
  width: auto;
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  transition: transform 400ms ease-in-out 0ms;
  transform: translateX(150%);
  background-color: var(--color-neutral-100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-inset-size-sm);
  margin-bottom: var(--spacing-size-2xs);
}
@media only screen and (min-width: 768px) {
  .toast-base {
    width: 398px;
  }
}
.toast-base--active {
  display: flex;
  transform: translateX(0%);
}

h2 {
  word-break: break-word;
  margin: 0;
  color: var(--color-neutral-600);
  font-size: var(--font-size-default);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-default);
}

p {
  margin: var(--spacing-size-4xs) 0;
  color: var(--color-neutral-600);
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
}

.toast-base__icon-position {
  height: 24px;
  width: 24px;
}
.toast-base__icon-position--warning {
  height: 27px;
}

clb-link {
  margin-top: var(--spacing-size-4xs);
}

.toast-base__icon {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: var(--border-radius-circular);
}
.toast-base__icon--negative {
  color: var(--color-feedback-danger-100);
  background-color: var(--color-feedback-danger-400);
}
.toast-base__icon--warning {
  color: var(--color-feedback-warning-100);
  background-color: var(--color-feedback-warning-400);
}
.toast-base__icon--positive {
  color: var(--color-feedback-positive-100);
  background-color: var(--color-feedback-positive-400);
}

.toast-base-content {
  flex: 1;
  word-break: break-word;
  display: flex;
  flex-direction: column;
}

.toast-base-action {
  align-self: flex-start;
}`;
export default styles;
