import { html, LitElement } from 'lit'
import { property, query, queryAll, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { when } from 'lit/directives/when.js'

import '@components/icon'
import '@components/tag'
import '@components/dropdown'
import '@components/dropdown-checkbox'

import { ClbDropdownCheckbox } from '@components/dropdown-checkbox'
import { ClbTag } from '@components/tag'

import { ClbMixin } from '@utils/ClbMixin'
import { dispatchCustomEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

enum KEYS {
  DOWN = 'ArrowDown',
  END = 'End',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  HOME = 'Home',
  LEFT = 'ArrowLeft',
  PAGE_DOWN = 'PageDown',
  PAGE_UP = 'PageUp',
  RIGHT = 'ArrowRight',
  SPACE = ' ',
  TAB = 'Tab',
  UP = 'ArrowUp'
}

enum KEYBOARD_ACTIONS {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  UP = 'UP',
  DOWN = 'DOWN',
  SELECT = 'SELECT'
}

const getActionFromKey = (key: string, open: boolean) => {
  switch (key) {
    case KEYS.ESCAPE:
      return KEYBOARD_ACTIONS.CLOSE
    case KEYS.ENTER:
      return open ? KEYBOARD_ACTIONS.SELECT : KEYBOARD_ACTIONS.OPEN
    case KEYS.DOWN:
      return open ? KEYBOARD_ACTIONS.DOWN : KEYBOARD_ACTIONS.OPEN
    case KEYS.UP:
      return open ? KEYBOARD_ACTIONS.UP : KEYBOARD_ACTIONS.OPEN
    default:
      break
  }
}

@registerElement('clb-multi-select')
export class ClbMultiSelect extends ClbMixin(LitElement) {
  @property({ type: Array }) data?: { value: string; label: string }[] = []
  @property({ type: Array }) value?: string[] = []
  @property({ type: Array }) hiddenTags?: { value: string; label: string }[] =
    []
  @property({ type: Number }) maxTagCount = 3
  @property({ type: String }) placeholder = ''
  @property({ type: String }) label = ''
  @property({ type: String }) error = ''
  @property({ type: String }) size = 'lg'
  @property({ type: String }) name = ''
  @property({ type: String }) applyButtonLabel = 'Apply'
  @property({ type: String }) clearButtonLabel = 'Clear'
  @property({ type: String }) requiredMessage = 'obrigatório'
  @property({ type: String }) optionalMessage = 'opcional'
  @property({ type: Boolean }) showMessage = false
  @property({ type: Boolean }) required = false
  @property({ type: Boolean }) disabled = false
  @property({ type: Boolean }) open = false

  @state() _inputHasFocus = false
  @state() _highlightIndex = 0

  @query('input')
  _input: HTMLElement

  @queryAll('clb-dropdown-checkbox')
  _options: NodeListOf<ClbDropdownCheckbox>

  @queryAll('clb-tag')
  _tags: NodeListOf<ClbTag>

  static styles = [styles]

  static events = {
    onClbValueChange: 'onClbValueChange',
    onClbApply: 'onClbApply',
    onClbClear: 'onClbClear'
  }

  get _scrollContainer() {
    return this.shadowRoot
      .querySelector('clb-dropdown')
      .shadowRoot.querySelector('#dropdown-list')
  }

  get _selectionValue() {
    const data = []
    const items = this._options
    items.forEach((item) => {
      if (item['checked']) {
        data.push(item.getAttribute('value'))
      }
    })

    return data
  }

  connectedCallback(): void {
    super.connectedCallback()
    this.addEventListener('focusout', this._handleFocusOut)
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    this.removeEventListener('focusout', this._handleFocusOut)
  }

  private _handleFocusOut(event: FocusEvent) {
    if (this.open && !this.contains(event.relatedTarget as Node)) {
      event.stopPropagation()
      this.handleMenuState(false)
    }
  }

  private _handleTriggerClick(event: MouseEvent) {
    event.stopPropagation()
    this.handleMenuState()
  }

  private _handleKeyDown(event: KeyboardEvent) {
    // ignore keydown when focus on tags
    if ([...this._tags].some((tag) => event.composedPath().includes(tag))) {
      return
    }

    const action = getActionFromKey(event.key, this.open)

    switch (action) {
      case KEYBOARD_ACTIONS.OPEN:
        return this.handleMenuState(true)
      case KEYBOARD_ACTIONS.CLOSE:
        return this.handleMenuState(false)
      case KEYBOARD_ACTIONS.UP:
      case KEYBOARD_ACTIONS.DOWN:
        return this.handleKeyboardNavigation(action)
      case KEYBOARD_ACTIONS.SELECT:
        return this.onOptionSelect(this._highlightIndex)
      default:
        break
    }
  }

  private _handleInputFocus() {
    this._inputHasFocus = true
  }

  private _handleInputBlur() {
    this._inputHasFocus = false
  }

  private handleKeyboardNavigation(
    action: KEYBOARD_ACTIONS.UP | KEYBOARD_ACTIONS.DOWN
  ) {
    const min = 0
    const max = Math.max(this.data.length - 1, min)

    this._highlightIndex =
      action === KEYBOARD_ACTIONS.DOWN
        ? Math.min(max, this._highlightIndex + 1)
        : Math.max(min, this._highlightIndex - 1)

    this._options[this._highlightIndex].scrollIntoView({ block: 'nearest' })
  }

  private onOptionSelect(index: number) {
    const option = this._options[index]
    option.checked = !option.checked
  }

  private onCheckboxSelect(index: number) {
    this._highlightIndex = index
    this.onOptionSelect(index)
  }

  private _dispatchChangeEvent = () => {
    dispatchCustomEvent({
      eventName: ClbMultiSelect.events.onClbValueChange,
      eventOptions: { detail: { value: [...this.value] } },
      dispatcher: this
    })
  }

  private onApplyFilter(e: Event) {
    this.value = this._selectionValue
    this.handleMenuState(false)
    dispatchCustomEvent({
      eventName: ClbMultiSelect.events.onClbApply,
      eventOptions: { ...e, detail: { value: this._selectionValue } },
      dispatcher: this
    })
    this._dispatchChangeEvent()
  }

  private _onClearFilter(e: Event) {
    this.value = []
    this.handleMenuState(false)

    dispatchCustomEvent({
      eventName: ClbMultiSelect.events.onClbClear,
      eventOptions: { ...e, detail: { value: [] } },
      dispatcher: this
    })

    this._dispatchChangeEvent()
  }

  private onHiddenTagsClick = (event: MouseEvent) => {
    event.stopPropagation()
    this.handleMenuState(!this.open)

    this.value.length = this.value.length - this.hiddenTags.length
    this.resetCheckboxes()
  }

  private onTagClick = (
    event: MouseEvent,
    tag: { label: string; value: string }
  ) => {
    event.stopPropagation()
    this.value = this.value.filter((item) => item !== tag.value)
    this.handleMenuState(false)
    this._dispatchChangeEvent()
  }

  private handleMenuState(open = !this.open) {
    this._highlightIndex = 0

    this.resetCheckboxes()

    this.open = open
  }

  private resetCheckboxes() {
    this._options.forEach((item) => {
      item.checked = this.value.includes(item.value)
    })
  }
  private _toggleVisibilityDropDown(event: CustomEvent<{ open: boolean }>) {
    if (!event.detail.open) {
      this.handleMenuState(false)
    }
  }

  private _renderSelectedList = () => {
    const tabIndex = this.open ? -1 : 0
    const visibleTags = this.value.map((selected) =>
      this.data.find((item) => item.value === selected)
    )
    this.hiddenTags = visibleTags.splice(this.maxTagCount)

    const renderedTags = html` ${visibleTags.map(
      (tag) => html`
        <clb-tag
          .tabindex="${tabIndex}"
          ?disabled=${this.disabled}
          type="filter"
          @onClbRemove=${(event: MouseEvent) => this.onTagClick(event, tag)}
          label="${tag.label}"
        ></clb-tag>
      `
    )}`

    return html`
      <div>
        ${renderedTags}
        ${when(
          !!this.hiddenTags.length,
          () => html`
            <clb-tag
              .tabindex="${tabIndex}"
              ?disabled=${this.disabled}
              type="filter"
              @onClbRemove=${this.onHiddenTagsClick}
              label="${this.hiddenTags.length} +"
            ></clb-tag>
          `
        )}
      </div>
    `
  }

  private _renderDropdown = () => {
    return html` <clb-dropdown
      width="unset"
      @onDropdownVisibilityChange=${this._toggleVisibilityDropDown}
      .open="${this.open}"
      type="multiselect"
      .buttonsList=${[
        {
          id: 'clear-btn',
          label: this.clearButtonLabel,
          callback: (e: Event) => this._onClearFilter(e)
        },
        {
          id: 'apply-btn',
          label: this.applyButtonLabel,
          callback: (e: Event) => this.onApplyFilter(e)
        }
      ]}
    >
      ${this._renderTrigger()}
      ${this.data.map(
        (item, index) => html`
          <clb-dropdown-checkbox
            slot="dropdown-content"
            tabindex="-1"
            ?highlighted=${index === this._highlightIndex}
            value=${item.value}
            @onClbDropdownChange=${() => {
              this.onCheckboxSelect(index)
            }}
            label="${item.label}"
          ></clb-dropdown-checkbox>
        `
      )}
    </clb-dropdown>`
  }

  private _renderTrigger = () => {
    const hasValue = Boolean(this.value.length)
    const placeholderText = hasValue ? '' : this.placeholder

    return html` <div
      slot="dropdown-trigger"
      id="multiselect-trigger"
      class="multiselect__select"
      @click=${this._handleTriggerClick}
      @keydown=${this._handleKeyDown}
    >
      <div class="multiselect__input-container">
        <input
          aria-multiline="false"
          role="textbox"
          aria-label="${this.label || 'multiselect-label'}"
          name="${this.name}"
          @focus=${this._handleInputFocus}
          @blur=${this._handleInputBlur}
          class="multiselect__input"
          id="trigger-label"
          type="text"
          placeholder="${placeholderText}"
          readonly
          .disabled="${this.disabled}"
        />
        ${when(hasValue, this._renderSelectedList)}
      </div>
      <clb-icon
        class="multiselect__chevron"
        icon="${this.open ? 'ChevronUp' : 'ChevronDown'}"
      ></clb-icon>
    </div>`
  }

  private _renderErrorMessage = () => html`
    <div class="multiselect__helper" id="helper-text">
      <clb-icon icon="Spam" size="sm"></clb-icon>
      <span>${this.error}</span>
    </div>
  `

  private _renderLabel = () => html`
    <label class="multiselect__label">
      ${this.label}
      ${when(
        this.required,
        () =>
          this.showMessage
            ? html`
                <span class="multiselect__label--message"
                  >(${this.requiredMessage})</span
                >
              `
            : null,
        () =>
          this.showMessage
            ? html`
                <span class="multiselect__label--message"
                  >(${this.optionalMessage})</span
                >
              `
            : null
      )}
    </label>
  `

  render() {
    return html`
      <div
        class="${classMap({
          ['multiselect']: true,
          ['multiselect--sm']: this.size === 'sm',
          ['multiselect--open']: this.open,
          ['multiselect--disabled']: this.disabled,
          ['multiselect--error']: !!this.error,
          ['multiselect--focused']: this._inputHasFocus
        })}"
      >
        ${when(this.size !== 'sm', this._renderLabel)}
        <!--  -->
        ${this._renderDropdown()}
        <!--  -->
        ${when(this.error && !this.disabled, this._renderErrorMessage)}
      </div>
    `
  }
}
