import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'

import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-progress-bar')
export class ClbProgressBar extends ClbMixin(LitElement) {
  @property({ type: String }) label = ''
  @property({ type: String }) description = ''
  @property({ type: Number }) value = 0
  @property({ type: Number }) total = 100
  @property({ type: Number }) decimalPlaces = 2
  static styles = [styles]

  private getFormattedPercentage = () => {
    const porcent = ((this.value * 100) / this.total)
      .toFixed(this.decimalPlaces)
      .toString()
      .replace(/(^0+(?=\d))|(,?0+$)/g, '')
    const dividerInDot = porcent.split('.')
    return dividerInDot[1].length ? porcent : dividerInDot[0]
  }

  render() {
    return html`
      <div class="progress-bar">
        ${this.label &&
        html`<label class="progress-bar__label">${this.label}</label>`}
        <progress
          value=${this.value}
          max="${this.total}"
          class="progress-bar__progress"
          role="progressbar"
          aria-valuemin="${0}"
          aria-valuemax="${100}"
          aria-valuenow="${this.getFormattedPercentage()}"
          aria-label="${this.label || 'progress-bar'}"
        ></progress>
        <div class="progress-bar__footer">
          <span class="progress-bar__description">${this.description}</span>
          <span class="progress-bar__percentage"
            >${this.getFormattedPercentage()}%</span
          >
        </div>
      </div>
    `
  }
}
