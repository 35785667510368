import {css} from 'lit';
export const styles = css`:host {
  display: flex;
}

.tooltip {
  position: relative;
  display: flex;
}
.tooltip__info {
  max-width: 224px;
  max-height: 96px;
  width: max-content;
  visibility: hidden;
  display: none;
  box-sizing: border-box;
  position: absolute;
  background-color: var(--color-neutral-400);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-inset-size-xs);
  overflow: hidden;
  box-shadow: var(--shadow-level-2) rgba(0, 0, 0, 0.16);
}
.tooltip__label {
  position: relative;
  width: fit-content;
  color: var(--color-neutral-100);
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  background-color: var(--color-neutral-400);
  line-height: var(--line-height-md);
  text-align: left;
}
.tooltip--top {
  justify-content: center;
}
.tooltip--top .tooltip__info {
  bottom: 100%;
}
.tooltip--top-start .tooltip__info {
  bottom: 100%;
  left: 0;
}
.tooltip--top-end .tooltip__info {
  bottom: 100%;
  right: 0;
}
.tooltip--right {
  align-items: center;
}
.tooltip--right .tooltip__info {
  left: 100%;
}
.tooltip--right-start {
  align-items: flex-start;
}
.tooltip--right-start .tooltip__info {
  left: 100%;
}
.tooltip--right-end {
  align-items: flex-end;
}
.tooltip--right-end .tooltip__info {
  left: 100%;
}
.tooltip--bottom {
  justify-content: center;
}
.tooltip--bottom .tooltip__info {
  top: 100%;
}
.tooltip--bottom-start .tooltip__info {
  top: 100%;
  left: 0;
}
.tooltip--bottom-end .tooltip__info {
  top: 100%;
  right: 0;
}
.tooltip--left {
  align-items: center;
}
.tooltip--left .tooltip__info {
  right: 100%;
}
.tooltip--left-start {
  align-items: flex-start;
}
.tooltip--left-start .tooltip__info {
  right: 100%;
}
.tooltip--left-end {
  align-items: flex-end;
}
.tooltip--left-end .tooltip__info {
  right: 100%;
}
.tooltip--default {
  justify-content: center;
}
.tooltip--default .tooltip__info {
  top: 45px;
}
.tooltip:hover .tooltip__info {
  visibility: visible;
  display: inline-flex;
  width: max-content;
  margin: calc(8px - var(--spacing-size-5xs));
  z-index: 99999;
}`;
export default styles;
