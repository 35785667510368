import {css} from 'lit';
export const styles = css`.full-page-flow {
  height: 100vh;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  flex-direction: column;
  width: calc(100vw - (100vw - 100%));
  transform: translateY(100%);
  transition: 1s ease;
  background-color: white;
}
.full-page-flow--opened {
  min-height: 100vh;
  display: flex;
  transform: translateY(0);
}
.full-page-flow--closed {
  height: 0px;
  overflow: hidden;
}
.full-page-flow__relative {
  position: relative;
  min-height: 100vh;
  background-color: var(--color-neutral-100);
  overflow: auto;
}
.full-page-flow__relative::-webkit-scrollbar {
  width: var(--border-width-lg);
  border-radius: var(--border-radius-default);
  background: var(--color-neutral-200);
}
.full-page-flow__relative::-webkit-scrollbar-thumb {
  background: var(--color-neutral-400);
  border-radius: var(--border-radius-default);
}
.full-page-flow__header {
  position: fixed;
  top: 0;
  right: var(--border-width-lg);
  width: 100%;
  height: 88px;
  background-color: var(--color-neutral-100);
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.full-page-flow__heading {
  min-width: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.full-page-flow__buttons {
  margin-left: 2rem;
  display: flex;
  gap: 2rem;
  z-index: 2;
}
.full-page-flow__close {
  margin-right: 2rem;
}
.full-page-flow__progress-bar--show {
  top: 88px;
  left: 0;
  width: 100%;
  position: absolute;
  background-color: var(--color-neutral-background);
}
.full-page-flow__progress-bar--hide {
  display: none;
}
.full-page-flow__slot__container {
  position: absolute;
  top: 88px;
  min-height: calc(100vh - 88px - 2 * var(--spacing-inset-size-xl) - (100vh - 100%));
  padding: var(--spacing-inset-size-xl);
  width: calc(100vw - 2 * var(--spacing-inset-size-xl) - (100vw - 100%));
  background-color: var(--color-neutral-background);
  overflow: auto;
}
.full-page-flow__slot__container-progress {
  top: 120px;
  min-height: calc(100vh - 120px - 2 * var(--spacing-inset-size-xl) - (100vh - 100%));
}`;
export default styles;
