import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'

import { ClbMixin } from '@utils/ClbMixin'
import { createEvent, bubbleEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import { Rel, Target, HrefLangIsoCode } from './props.types'
import styles from './styles.scss'

@registerElement('clb-link')
export class ClbLink extends ClbMixin(LitElement) {
  @property({ type: String }) href = '#'

  @property({ type: String }) hreflang: HrefLangIsoCode = 'x-default'

  @property({ type: String }) rel: Rel = 'nofollow'

  @property({ type: String }) target: Target = '_self'

  @property({ type: Boolean }) disabled = false

  @property({ type: Boolean }) download = false

  @property({ type: Boolean }) isSpa = false

  static styles = [styles]

  static events = {
    onClbClick: 'onClbClick'
  }

  private getClassAttributes = (): ClassInfo => ({
    [`link`]: true,
    [`link--disabled`]: this.disabled
  })

  private _bubbleEvent = (evt: Event): void => {
    if (this.isSpa) {
      evt.preventDefault()
    }
    const event = createEvent(ClbLink.events.onClbClick)
    bubbleEvent(event, evt.composedPath()[0] as Element)
    this.dispatchEvent(event)
  }

  render() {
    return html`
      <a
        class="${classMap(this.getClassAttributes())}"
        href="${this.href}"
        hreflang=${this.hreflang}
        rel=${this.rel}
        target=${this.target}
        ?download=${this.download}
        tabindex="0"
        @click=${this._bubbleEvent}
        isSpa=${this.isSpa}
      >
        <slot></slot>
      </a>
    `
  }
}
