import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'

import { IconPositionType, StyleType } from '@components/button/props.types'
import { Icons } from '@components/icon/props.types'

import '@components/button'
import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

import { Sizes, Orientations, ButtonAttributes } from './props.types'
import styles from './styles.scss'

const SUPPORTED_BUTTONS_ARRANGEMENTS = [
  { type: 'simple', arrangement: ['secondary', 'primary'] },
  { type: 'cancel', arrangement: ['tertiary', 'primary'] },
  { type: 'triple', arrangement: ['tertiary', 'secondary', 'primary'] },
  { type: 'modal-danger', arrangement: ['tertiary', 'danger'] }
]
const ERROR_MESSAGES_TEMPLATES = {
  COULDNT_CONVERT_PROPS: () =>
    `clb-button-group: Não foi possível converter os atributos de clb-button-group.`,
  INVALID_BUTTON_TYPE: (type, validTypes) =>
    `clb-button: O tipo de botão fornecido "${type}" não está entre os tipos suportados: ${validTypes.join(
      ', '
    )}`,
  INCORRECT_ARRANGEMENT_TYPE: (
    type,
    expectedButtonsLength,
    receivedButtonsLength
  ) =>
    `clb-button-group: O tipo de arranjamento escolhido foi "${type}"", que espera ${expectedButtonsLength} botões, porém foi recebido ${receivedButtonsLength}.`
}

@registerElement('clb-button-group')
export class ClbButtonGroup extends ClbMixin(LitElement) {
  @property({ type: String }) size: Sizes = 'md'

  @property({ type: String }) orientation: Orientations = 'line'

  @property({ type: Array }) buttonsList: Array<ButtonAttributes> = []

  @property({ type: String }) type = 'simple'

  static styles = [styles]

  private _hasValidProps() {
    const that = this

    function hasValidType() {
      const foundType = !!SUPPORTED_BUTTONS_ARRANGEMENTS.find(
        (arrangement) => arrangement.type === that.type
      )
      if (!foundType)
        console.warn(
          ERROR_MESSAGES_TEMPLATES.INVALID_BUTTON_TYPE(
            that.type,
            SUPPORTED_BUTTONS_ARRANGEMENTS.map(
              (arrangement) => arrangement.type
            )
          )
        )
      return foundType
    }

    function validateArrangementType() {
      const receivedButtonsLength = that.buttonsList.length
      const arrangementTypeExpectedLength = SUPPORTED_BUTTONS_ARRANGEMENTS.find(
        (arrangement) => arrangement.type === that.type
      )?.arrangement?.length
      const isArrangementValid =
        receivedButtonsLength === arrangementTypeExpectedLength
      if (!isArrangementValid)
        console.warn(
          ERROR_MESSAGES_TEMPLATES.INCORRECT_ARRANGEMENT_TYPE(
            that.type,
            arrangementTypeExpectedLength,
            receivedButtonsLength
          )
        )
      return isArrangementValid
    }

    return hasValidType() && validateArrangementType()
  }

  _defineIcon = () => {
    if (this.type === 'cancel')
      return {
        icons: ['Blocked', ''] as Icons[],
        positions: ['left', 'none'] as IconPositionType[]
      }
    if (this.type === 'modal-danger')
      return {
        icons: ['Blocked', 'Cancel'] as Icons[],
        positions: ['left', 'left'] as IconPositionType[]
      }
    return { icons: [], positions: [] }
  }

  render() {
    if (!this._hasValidProps() || !this.buttonsList.length) return

    return html`
      <div class="button-group button-group--${this.orientation}">
        ${this.buttonsList.map((button, index) => {
          const buttonType = SUPPORTED_BUTTONS_ARRANGEMENTS.find(
            (arrangement) => arrangement.type === this.type
          ).arrangement[index] as StyleType
          const { icons, positions } = this._defineIcon()

          return html`
            <clb-button
              id="${button.id}"
              title="${button.title}"
              aria-label="${button.ariaLabel}"
              size="${this.size}"
              styleType="${buttonType}"
              icon="${icons[index] || button.icon}"
              iconPosition="${positions[index] ||
              button.iconPosition ||
              'none'}"
              .blockedWidth="${this.orientation === 'list'}"
              ?loading="${button?.loading}"
              ?disabled="${button?.disabled}"
              data-testid="${button?.dataTestid || ''}"
              data-cy="${button?.dataCy || ''}"
              @onClbClick="${button.callback}"
            >
              ${button.label}
            </clb-button>
          `
        })}
      </div>
    `
  }
}
