import {css} from 'lit';
export const styles = css`.dropdown {
  position: relative;
}
.dropdown__container {
  position: absolute;
  z-index: 20;
  width: 100%;
}
.dropdown__list {
  background-color: var(--color-neutral-100);
  border: var(--border-width-default) solid var(--color-neutral-200);
  border-radius: var(--border-radius-default);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dropdown__content {
  max-height: 288px;
  overflow-y: auto;
}
.dropdown__content::-webkit-scrollbar {
  width: var(--border-width-lg);
  border-radius: var(--border-radius-default);
  background: var(--color-neutral-200);
}
.dropdown__content::-webkit-scrollbar-thumb {
  background: var(--color-neutral-400);
  border-radius: var(--border-radius-default);
}
.dropdown__footer {
  background-color: var(--color-neutral-background);
  padding: var(--spacing-inset-size-sm);
  display: flex;
  justify-content: flex-end;
}

::slotted(*) {
  cursor: pointer;
}`;
export default styles;
