import {css} from 'lit';
export const styles = css`:host([disabled]) {
  pointer-events: none;
}
:host([disabled]) .button-icon {
  pointer-events: none;
}

.button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-neutral-600);
  border-radius: var(--border-radius-sm);
  border: 0px;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(var(--vibe-soft));
  background-color: transparent;
}
.button-icon:focus:not(:disabled) {
  outline: 2px solid var(--color-contrast-focused);
  outline-offset: 2px;
  border-radius: var(--border-radius-default);
  border-color: var(--color-brand-300);
  border-width: var(--border-width-default);
}
.button-icon:focus-visible:not(:disabled) {
  outline: 2px solid var(--color-contrast-focused);
}
.button-icon:disabled {
  pointer-events: none;
  color: var(--color-neutral-300);
}
.button-icon:active {
  opacity: var(--opacity-level-1);
}
.button-icon--active, .button-icon:hover {
  background-color: var(--color-action-hover) !important;
}
.button-icon--black {
  color: var(--color-contrast-black);
}
.button-icon--sm {
  width: 36px;
  height: 36px;
}
.button-icon--sm clb-icon {
  height: 16px;
  width: 16px;
}
.button-icon--lg {
  width: 48px;
  height: 48px;
}`;
export default styles;
