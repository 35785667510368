import { LitElement, html } from 'lit'
import { property, query } from 'lit/decorators.js'

import { ClbMixin } from '@utils/ClbMixin'
import { createEvent, bubbleEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import { Orientation } from './props.types'
import styles from './styles.scss'

@registerElement('clb-checkbox')
export class ClbCheckbox extends ClbMixin(LitElement) {
  @property({ type: String }) label = ''

  @property({ type: String }) name = ''

  @property({ type: String }) id = ''

  @property({ type: String }) value = ''

  @property({ type: String }) orientation: Orientation = ''

  @property({ type: Boolean }) indeterminate = false

  @property({ type: Boolean }) checked = false

  @property({ type: Boolean }) disabled = false

  @property({ type: Boolean }) required = false

  @property({ type: Boolean }) hiddenLabel = false

  @query('input')
  protected _input: HTMLInputElement

  static styles = [styles]

  static events = {
    onClbChange: 'onClbChange'
  }

  _onChange = (evt: InputEvent) => {
    const { checked, indeterminate, value, name } = this._input
    const event = createEvent(ClbCheckbox.events.onClbChange, {
      cancelable: true,
      ...evt,
      detail: { checked, indeterminate, value, name }
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)

    if (this.dispatchEvent(event)) {
      this.checked = checked
      this.indeterminate = indeterminate
    }
  }

  render() {
    return html`
      <label
        class="checkbox checkbox--${this.orientation}"
        for="${this.id || 'checkbox'}"
      >
        <input
          id="${this.id || 'checkbox'}"
          ?disabled=${this.disabled}
          type="checkbox"
          class="${this.label ? 'has-label' : ''} ${this.indeterminate
            ? 'indeterminate'
            : ''}"
          value="${this.value}"
          name="${this.name}"
          .checked=${this.checked}
          ?required=${this.required}
          aria-label="${this.label || 'checkbox'}"
          @change=${this._onChange}
        />
        <span class="${this.hiddenLabel ? 'hidden-label' : ''}"
          >${this.label}</span
        >
      </label>
    `
  }
}
