import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'

import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

import '@components/icon/'

import styles from './styles.scss'

@registerElement('clb-dropdown-item')
export class ClbDropdownItem extends ClbMixin(LitElement) {
  @property({ type: Boolean }) disabled = false

  static styles = [styles]

  render() {
    return html`
      <span
        class="${classMap({
          [`dropdown-item`]: true,
          [`dropdown-item--disabled`]: this.disabled
        })}"
        tabindex="0"
      >
        <slot></slot>
      </span>
    `
  }
}
