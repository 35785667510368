import {css} from 'lit';
export const styles = css`.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading:before {
  content: "";
  display: block;
  position: absolute;
  border: var(--border-width-xs) var(--color-neutral-300) solid;
  border-radius: var(--border-radius-circular);
  box-sizing: border-box;
}
.loading:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: var(--border-radius-circular);
  box-sizing: border-box;
  border-left: transparent solid;
  transform: rotate(45deg);
  -webkit-animation: spin 1.618s infinite ease-in-out;
  animation: spin 1.618s infinite ease-in-out;
}
.loading--default:after {
  border-width: var(--border-width-sm);
  border-top: var(--color-action-default) solid;
  border-right: var(--color-action-default) solid;
  border-bottom: var(--color-action-default) solid;
}
.loading--brand:after {
  border-width: var(--border-width-sm);
  border-top: var(--color-contrast-brand) solid;
  border-right: var(--color-contrast-brand) solid;
  border-bottom: var(--color-contrast-brand) solid;
}
.loading--danger:after {
  border-width: var(--border-width-sm);
  border-top: var(--color-contrast-white) solid;
  border-right: var(--color-contrast-white) solid;
  border-bottom: var(--color-contrast-white) solid;
}
.loading--sm:before {
  height: 23px;
  width: 23px;
  margin: 0.5px 0 0 0.5px !important;
}
.loading--sm:after {
  border-width: var(--border-width-sm);
  height: 24px;
  width: 24px;
}
.loading--lg:before {
  height: 93px;
  width: 93px;
  margin: 1.5px 0 0 1.5px !important;
}
.loading--lg:after {
  border-width: var(--border-width-lg);
  height: 96px;
  width: 96px;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  to {
    border-top: transparent;
    border-right: transparent;
    border-bottom: transparent;
    -webkit-transform: rotate(765deg);
    transform: rotate(765deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  to {
    -webkit-transform: rotate(765deg);
    transform: rotate(765deg);
  }
}`;
export default styles;
