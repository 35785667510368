import {css} from 'lit';
export const styles = css`.input {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-regular);
}
.input-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 135px;
  background-color: var(--color-opacity-default);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) solid var(--color-neutral-300);
  margin-top: var(--spacing-size-4xs);
  margin-bottom: var(--spacing-size-4xs);
  padding: 0 var(--spacing-size-4xs);
}
.input-container input {
  width: 100%;
  border: transparent;
  outline: transparent;
  background-color: transparent;
  font-family: var(--font-family-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-regular);
  color: var(--color-neutral-600);
}
.input-container input::placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-neutral-300);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
}
.input-container input:hover {
  border: transparent;
  background-color: transparent;
}
.input-container input:focus {
  outline: transparent;
  border-color: transparent;
}
.input-container input:focus-visible {
  outline: transparent;
  border-color: transparent;
}
.input-container:hover {
  border: var(--border-width-default) solid var(--color-brand-300);
  background-color: var(--color-opacity-hover);
}
.input-container:hover::placeholder {
  color: var(--color-contrast-brand);
}
.input-container--lg {
  height: 48px;
}
.input-container--sm {
  height: 36px;
}
.input-container--error {
  background-color: var(--color-opacity-error) !important;
  border-color: var(--color-feedback-danger-300) !important;
}
.input-container--error::placeholder {
  color: var(--color-feedback-danger-300) !important;
}
.input-container--disabled {
  background: var(--color-opacity-disabled);
}
.input-container--disabled:hover {
  background: var(--color-opacity-disabled);
  border: var(--border-width-default) solid var(--color-neutral-300);
}
.input-container--disabled input {
  color: var(--color-neutral-300);
  border: none;
  outline: none;
}
.input-container--disabled input::placeholder {
  color: var(--color-neutral-300);
}
.input-container--disabled input:hover {
  border: none !important;
  outline: none;
  background-color: transparent;
}
.input-container--disabled input:hover::placeholder {
  color: var(--color-neutral-300);
}
.input-container clb-icon:last-of-type {
  cursor: pointer;
}
.input-label {
  display: inline-flex;
  flex-direction: row;
  gap: var(--spacing-size-5xs);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-semibold);
}
.input-label--message {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}
.input-label--disabled {
  color: var(--color-neutral-300) !important;
}
.input-label--sm {
  display: none;
}
.input-helper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-size-5xs);
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
}
.input-helper span {
  display: none;
}
.input-helper--error {
  color: var(--color-feedback-danger-300);
}
.input-helper--error span {
  display: inline-block;
  height: var(--font-size-default);
}
.input-helper--error span svg {
  height: var(--font-size-default);
  width: var(--font-size-default);
}`;
export default styles;
