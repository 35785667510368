import { LitElement } from 'lit'
import { property } from 'lit/decorators.js'

import { ClbMixin } from '@utils/ClbMixin'

import { Sizes, Types } from './props.types'

export class ClbInputBase extends ClbMixin(LitElement) {
  @property({ type: String }) type: Types = 'text'

  @property({ type: String }) size: Sizes = 'lg'

  @property({ type: String }) name = ''

  @property({ type: String }) pattern = ''

  @property({ type: String }) placeholder = ''

  @property({ type: String }) optionalMessage = 'optional'

  @property({ type: String }) requiredMessage = 'obrigatório'

  @property({ type: Boolean }) showMessage = false

  @property({ type: Boolean }) error = false

  @property({ type: Boolean }) disabled = false

  @property({ type: Boolean }) required = false
}
