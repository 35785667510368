type Constructor<T> = new (...args: any[]) => T

export const ClbMixin = <T extends Constructor<HTMLElement>>(Base: T) =>
  class extends Base {
    static tagName = ''

    static events = {}
    static slots = {}

    static get eventList() {
      return Object.values(this.events)
    }

    static get slotList() {
      return Object.values(this.slots)
    }
  }
