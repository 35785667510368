import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import { when } from 'lit/directives/when.js'

import { icons } from '@celebration/assets/dist'

import { OptionAttributes } from '@components/select/props.types'

import { ClbMixin } from '@utils/ClbMixin'
import { dispatchCustomEvent, bubbleEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-select')
export class ClbSelect extends ClbMixin(LitElement) {
  @property({ type: String }) id = ''
  @property({ type: String }) name = ''
  @property({ type: String }) label = ''
  @property({ type: String }) placeholder = ''
  @property({ type: String }) value = ''
  @property({ type: String }) size = ''
  @property({ type: String }) helperText = ''
  @property({ type: String }) requiredMessage = 'obrigatório'
  @property({ type: String }) optionalMessage = 'opcional'
  @property({ type: Boolean }) showHelperText = false
  @property({ type: Boolean }) showMessage = false
  @property({ type: Boolean }) required = false
  @property({ type: Boolean }) disabled = false
  @property({ type: Boolean }) error = false
  @property({ type: Number }) maxHeight = 0
  @property({ type: Array }) options: Array<OptionAttributes> = []

  selectedOption = false

  static styles = [styles]

  static events = {
    onClbChange: 'onClbChange'
  }

  get patternSize() {
    return ['sm', 'lg'].includes(this.size) ? this.size : 'lg'
  }

  private getHelperClassAttributes = (): ClassInfo => ({
    [`select-helper`]: true,
    [`select-helper--disabled`]: this.disabled,
    [`select-helper--error`]: this.error && this.helperText
  })

  private getLabelClassAttributes = (): ClassInfo => ({
    [`select-label`]: true,
    [`select-label--${this.patternSize}`]: true,
    [`select-label--disabled`]: this.disabled
  })

  private getSelectClassAttributes = (): ClassInfo => ({
    [`select-input`]: true,
    [`select-input--error`]: !this.disabled && this.error,
    [`select-input--disabled`]: this.disabled,
    [`select-input--${this.patternSize}`]: true,
    [`select-input--placeholder-initial`]:
      !this.error && !this.selectedOption && !!this.placeholder && !this.value
  })

  private _bubbleEvent(evt: Event): void {
    const targetElement =
      this.shadowRoot.firstElementChild.querySelector('select')
    bubbleEvent(evt, targetElement)
    this.selectedOption = true

    this.value = targetElement.value
    this.requestUpdate('value')

    if (evt.type === 'input') {
      dispatchCustomEvent({
        eventName: ClbSelect.events.onClbChange,
        eventOptions: { detail: { value: targetElement?.value } },
        targetElement,
        dispatcher: this
      })
    }
  }

  render() {
    return html`
      <div class="select-wrapper select-wrapper--${this.patternSize}">
        <label
          id="select-label"
          for="select-${this.id}"
          class="${classMap(this.getLabelClassAttributes())}"
        >
          ${this.label}
          ${when(
            this.required,
            () =>
              this.showMessage
                ? html`
                    <span class="select-label--message"
                      >(${this.requiredMessage})</span
                    >
                  `
                : null,
            () =>
              this.showMessage
                ? html`
                    <span class="select-label--message"
                      >(${this.optionalMessage})</span
                    >
                  `
                : null
          )}
        </label>

        <div class="select-combo select-combo--${this.patternSize}">
          <select
            name="${this.name}"
            class="${classMap(this.getSelectClassAttributes())}"
            .value="${this.value}"
            ?disabled=${this.disabled}
            id="select-${this.id}"
            ?required=${this.required}
            tabindex="${this.tabIndex}"
            @input="${this._bubbleEvent}"
          >
            ${when(
              this.placeholder,
              () =>
                html`<option disabled ?selected=${!this.value}>
                  ${this.placeholder}
                </option>`
            )}
            ${this.options.map(
              (option) => html` <option
                key=${option.key}
                value=${option.value || option.label}
                ?selected=${option.value === this.value}
                ?disabled=${option.disabled}
              >
                ${option.label}
              </option>`
            )}
          </select>
        </div>
        ${when(
          this.showHelperText,
          () => html`
            <span class="${classMap(this.getHelperClassAttributes())}">
              <span>${unsafeSVG(icons['Spam'])}</span>
              ${this.helperText}
            </span>
          `,
          () => null
        )}
      </div>
    `
  }
}
