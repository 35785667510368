import {css} from 'lit';
export const styles = css`.text {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  overflow-wrap: break-word;
}
.text--sm {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
}
.text--md {
  font-size: var(--font-size-default);
  line-height: var(--line-height-md);
}
.text--lg {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
}
.text--disabled {
  color: var(--color-neutral-300) !important;
}
.text--neutral {
  color: var(--color-neutral-600);
}
.text--neutral--100 {
  color: var(--color-neutral-100);
}
.text--neutral--200 {
  color: var(--color-neutral-200);
}
.text--neutral--300 {
  color: var(--color-neutral-300);
}
.text--neutral--400 {
  color: var(--color-neutral-400);
}
.text--neutral--500 {
  color: var(--color-neutral-500);
}
.text--neutral--600 {
  color: var(--color-neutral-600);
}
.text--success {
  color: var(--color-feedback-positive-300);
}
.text--success--100 {
  color: var(--color-feedback-positive-100);
}
.text--success--200 {
  color: var(--color-feedback-positive-200);
}
.text--success--300 {
  color: var(--color-feedback-positive-300);
}
.text--success--400 {
  color: var(--color-feedback-positive-400);
}
.text--success--500 {
  color: var(--color-feedback-positive-500);
}
.text--success--600 {
  color: var(--color-feedback-positive-600);
}
.text--warning {
  color: var(--color-feedback-warning-300);
}
.text--warning--100 {
  color: var(--color-feedback-warning-100);
}
.text--warning--200 {
  color: var(--color-feedback-warning-200);
}
.text--warning--300 {
  color: var(--color-feedback-warning-300);
}
.text--warning--400 {
  color: var(--color-feedback-warning-400);
}
.text--warning--500 {
  color: var(--color-feedback-warning-500);
}
.text--warning--600 {
  color: var(--color-feedback-warning-600);
}
.text--danger {
  color: var(--color-feedback-danger-300);
}
.text--danger--100 {
  color: var(--color-feedback-danger-100);
}
.text--danger--200 {
  color: var(--color-feedback-danger-200);
}
.text--danger--300 {
  color: var(--color-feedback-danger-300);
}
.text--danger--400 {
  color: var(--color-feedback-danger-400);
}
.text--danger--500 {
  color: var(--color-feedback-danger-500);
}
.text--danger--600 {
  color: var(--color-feedback-danger-600);
}`;
export default styles;
