import {css} from 'lit';
export const styles = css`clb-dropdown #dropdown-trigger {
  display: flex;
  align-items: center;
  font-size: var(--font-size-default);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-neutral-600);
}`;
export default styles;
