import { LitElement, html } from 'lit'
import { property } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'

import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

import { Sizes, Types } from './props.types'
import styles from './styles.scss'

@registerElement('clb-loading')
export class ClbLoading extends ClbMixin(LitElement) {
  @property({ type: String }) type: Types = 'default'

  @property({ type: String }) size: Sizes = 'lg'

  @property({ attribute: 'aria-label', type: String }) ariaLabel = 'carregando'

  static styles = [styles]

  private getClassAttributes = (): ClassInfo => ({
    [`loading`]: true,
    [`loading--${this.type}`]: true,
    [`loading--${this.size}`]: this.size
  })

  render() {
    return html`
      <div
        class="${classMap(this.getClassAttributes())}"
        role="progressbar"
        aria-label="${this.ariaLabel}"
      ></div>
    `
  }
}
