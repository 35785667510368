import { html } from 'lit'
import { property } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import { when } from 'lit/directives/when.js'

import { icons } from '@celebration/assets/dist'

import { ClbInputBase } from '@components/input-base'
import baseStyles from '@components/input-base/styles.scss'

import { dispatchCustomEvent, bubbleEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import {
  RegexValidators,
  regexValidatorsEnum,
  regexValiratorsMasksEnum
} from './props.types'

@registerElement('clb-input-number')
export class ClbInputNumber extends ClbInputBase {
  @property({ type: String }) label = ''

  @property({ type: String }) placeholder = ''

  @property({ attribute: 'helper-text', type: String }) helperText =
    'Helper text'

  @property({ attribute: 'input-mask', type: String })
  inputMask: RegexValidators = 'CPF'

  @property({ type: String }) value = ''

  @property({ type: String }) requiredMessage = 'obrigatório'

  @property({ type: String }) optionalMessage = 'opcional'

  @property({ type: String }) name = ''

  @property({ type: Boolean }) showMessage = false

  @property({ type: Boolean }) showHelperText = false

  @property({ type: Boolean }) required = false

  @property({ type: Boolean }) disabled = false

  @property({ type: Boolean }) error = false

  static styles = [baseStyles]

  static events = {
    onClbChange: 'onClbChange'
  }

  private getLabelClasses = (): ClassInfo => ({
    [`input-base-label`]: true,
    [`input-base-label--disabled`]: this.disabled,
    [`input-base-label--${super.size}`]: true
  })

  private getInputClasses = (): ClassInfo => ({
    [`input-base-input`]: true,
    [`input-base-input--${super.size}`]: true,
    [`input-base-input--error`]: this.error
  })

  private getHelperClasses = (): ClassInfo => ({
    [`input-base-helper`]: true,
    [`input-base-label--disabled`]: this.disabled,
    [`input-base-helper--error`]: this.error && this.helperText
  })

  private _bubbleEvent(evt: Event): void {
    const targetElement = this._filterInputCharacters(
      this.shadowRoot.firstElementChild.querySelector('input')
    )

    bubbleEvent(evt, targetElement)

    if (evt.type === 'input') {
      dispatchCustomEvent({
        eventName: ClbInputNumber.events.onClbChange,
        eventOptions: { detail: { value: targetElement?.value } },
        targetElement,
        dispatcher: this
      })
    }
    this._validateInput(targetElement.value)
  }

  private _validateInput(input: string): void {
    const testRaw = new RegExp(regexValidatorsEnum[this.inputMask])
    const testClean = new RegExp(regexValidatorsEnum[`${this.inputMask}clean`])
    if (this.inputMask != 'NONE') {
      if (testRaw.test(input) || testClean.test(input)) {
        this.error = false
      } else if (!input && !this.error) {
        this.error = false
      } else {
        this.error = true
        return
      }
    }
    this.value = input
    this.requestUpdate('value')
  }

  private _filterInputCharacters(
    inputElement: HTMLInputElement
  ): HTMLInputElement {
    const matchRegex = new RegExp(
      regexValidatorsEnum[`${this.inputMask}filter`]
    )
    inputElement.value = inputElement.value.match(matchRegex).toString()
    return inputElement
  }

  private _validValueInit(value: string): string {
    if (value) {
      this._validateInput(value)
      return value
    }
    return value
  }

  render() {
    return html`
      <div class="input-base">
        <label for=${this.id} class="${classMap(this.getLabelClasses())}">
          ${this.label}
          ${when(
            this.required,
            () =>
              this.showMessage
                ? html`
                    <span class="input-base-label--message"
                      >(${this.requiredMessage})</span
                    >
                  `
                : null,
            () =>
              this.showMessage
                ? html`
                    <span class="input-base-label--message"
                      >(${this.optionalMessage})</span
                    >
                  `
                : null
          )}
        </label>
        <input
          id="${this.id}"
          type="${this.type}"
          class="${classMap(this.getInputClasses())}"
          placeholder="${regexValiratorsMasksEnum[this.inputMask]}"
          name="${this.name}"
          .value="${this._validValueInit(this.value)}"
          pattern="${regexValidatorsEnum[this.inputMask]}"
          maxlength=${regexValiratorsMasksEnum[this.inputMask].length}
          ?disabled=${this.disabled}
          ?required=${this.required}
          @focus=${this._bubbleEvent}
          @blur=${this._bubbleEvent}
          @input=${this._bubbleEvent}
        />
        ${when(
          this.showHelperText,
          () => html`
            <span class="${classMap(this.getHelperClasses())}">
              <span>${unsafeSVG(icons['Spam'])}</span>
              ${this.helperText}
            </span>
          `,
          () => null
        )}
      </div>
    `
  }
}
