import {css} from 'lit';
export const styles = css`@charset "UTF-8";
.day {
  width: 41px;
  text-align: center;
  padding: 12px 0px;
  margin: 0px;
  font-size: var(--font-size-sm);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
  color: var(--color-neutral-600);
  cursor: pointer;
}
.day:hover {
  background-color: var(--color-action-hover);
  border-radius: var(--border-radius-default);
}
.day-selected {
  background-color: var(--color-action-default);
  border-radius: var(--border-radius-default);
}
.day-today {
  width: 41px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 12px 0 0 0;
  font-weight: var(--font-weight-semibold);
}
.day-today::after {
  content: "•";
  color: var(--color-neutral-600);
}
.day--in-last-month, .day--in-next-month {
  color: var(--color-neutral-300);
}`;
export default styles;
