import { html, LitElement } from 'lit'
import { property, state } from 'lit/decorators.js'

import { ClbMixin } from '@utils/ClbMixin'
import { dispatchCustomEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import '@components/dropdown'

import { monthsArray } from './props.types'
import styles from './styles.scss'

@registerElement('clb-calendar-month')
export class ClbCalendarMonth extends ClbMixin(LitElement) {
  @property({ type: String }) month = ''
  @property({ type: Array }) monthsArray = monthsArray

  @state() dropdownOpened = false
  @state() monthNumber = 0

  static styles = [styles]

  static events = {
    onClbSelectMonth: 'onClbSelectMonth'
  }

  private _bubbleEvent(): void {
    dispatchCustomEvent({
      eventName: ClbCalendarMonth.events.onClbSelectMonth,
      eventOptions: {
        cancelable: true,
        detail: {
          month: this.month,
          monthNumber: this.monthNumber,
          dropdownOpened: this.dropdownOpened,
          monthsArray: this.monthsArray
        }
      },
      dispatcher: this
    })
  }

  private _changeMonth(month: string) {
    for (let i = 0; i < this.monthsArray.length; i++) {
      if (this.monthsArray[i] === month) {
        this.month = month
        this.monthNumber = i + 1
      }
    }

    this._bubbleEvent()
  }

  render() {
    return html`<clb-dropdown
      anchorSelector="#dropdown-trigger"
      ?open=${this.dropdownOpened}
      @click=${() => (this.dropdownOpened = !this.dropdownOpened)}
    >
      <div id="dropdown-trigger" slot="dropdown-trigger">
        <p id="current-month-name">${this.month}</p>
        <clb-icon icon="ChevronDown" size="sm" />
      </div>
      ${this.monthsArray?.map(
        (month) => html` <clb-dropdown-item
          id="${month}"
          slot="dropdown-content"
          @click=${() => this._changeMonth(month)}
        >
          <clb-text>${month}</clb-text>
        </clb-dropdown-item>`
      )}
    </clb-dropdown>`
  }
}
