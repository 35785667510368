import {css} from 'lit';
export const styles = css`.progress-bar {
  display: flex;
  flex-direction: column;
  min-width: 56px;
}
.progress-bar__label {
  color: var(--color-neutral-600);
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-default);
  margin-bottom: var(--spacing-size-3xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.progress-bar__progress {
  height: 8px;
  background-image: var(--color-brand-gradient);
  border-radius: var(--border-radius-pill);
  margin-bottom: var(--spacing-size-4xs);
  width: auto;
  border: none;
}
.progress-bar__progress::-webkit-progress-bar {
  background-color: var(--color-neutral-200);
  border-radius: var(--border-radius-pill);
}
.progress-bar__progress::-webkit-progress-value {
  background-image: var(--color-brand-gradient);
  border-radius: var(--border-radius-pill);
}
.progress-bar__progress::-moz-progress-bar {
  background-image: var(--color-brand-gradient);
  border-radius: var(--border-radius-pill);
}
.progress-bar__footer {
  display: flex;
  justify-content: space-between;
  line-height: var(--line-height-default);
  padding-right: 2.5rem;
}
.progress-bar__description {
  color: var(--color-neutral-600);
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.progress-bar__percentage {
  color: var(--color-neutral-600);
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
}`;
export default styles;
