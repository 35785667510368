import { html, LitElement } from 'lit'
import { property, state } from 'lit/decorators.js'
import { when } from 'lit/directives/when.js'

import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'

import { monthsArray } from '@components/calendar-month/props.types'

import { ClbMixin } from '@utils/ClbMixin'
import { bubbleEvent, dispatchCustomEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import '@components/calendar-month'
import '@components/calendar-year'
import '@components/calendar-day'
import '@components/dropdown'
import '@components/dropdown-item'

import styles from './styles.scss'

@registerElement('clb-calendar')
export class ClbCalendar extends ClbMixin(LitElement) {
  @property({ type: String }) selectedDate = ''
  @property({ type: String }) name = ''
  @property({ type: String }) tooltipLabel = 'Data atual'
  @property({ type: Array }) monthsArray = monthsArray
  @property({ type: Number }) monthInNumber = dayjs().get('month')
  @property({ type: Number }) currentYear = dayjs().get('year')

  @state() year = ''
  @state() currentMonthName = ''
  @state() daysInLastMonth = []
  @state() daysInCurrentMonth = []
  @state() daysInNextMonth = []

  currentDate = new Date()
  currentDay = this.currentDate.getDate()

  static styles = [styles]

  static events = {
    onClbSelectDate: 'onClbSelectDate'
  }

  protected updated(changedProps) {
    if (
      changedProps.has('currentMonthName') ||
      changedProps.has('currentYear') ||
      changedProps.has('monthInNumber')
    ) {
      this.calendarChangeUpdate()
    }

    if (changedProps.has('selectedDate')) {
      this.renderCalendarByPropSelectedDate()
    }
  }

  protected firstUpdated() {
    this.calendarChangeUpdate()
  }

  private _bubbleEvent(evt: MouseEvent, day, month) {
    let formattedMonth = (this.monthInNumber + 1).toString()
    let formattedDay = day

    if (month <= 9) formattedMonth = this.addingZero(this.monthInNumber + 1)
    if (day <= 9) formattedDay = this.addingZero(day)

    this.selectedDate = `${formattedDay}/${formattedMonth}/${this.currentYear}`

    bubbleEvent(evt, evt.composedPath()[0] as Element)

    dispatchCustomEvent({
      eventName: ClbCalendar.events.onClbSelectDate,
      eventOptions: {
        cancelable: true,
        detail: {
          value: this.selectedDate,
          monthInNumber: this.monthInNumber,
          currentMonthName: this.currentMonthName,
          currentYear: this.currentYear,
          year: this.year
        }
      },
      targetElement: evt.composedPath()[0] as Element,
      dispatcher: this
    })
  }

  private monthNumberValid() {
    if (this.monthInNumber > 11) {
      this.monthInNumber = 11
    } else if (this.monthInNumber < 0) {
      this.monthInNumber = 0
    }
  }

  private calendarChangeUpdate() {
    this.monthNumberValid()
    this.currentMonthName = this.monthsArray[this.monthInNumber]
    this.year = this.currentYear.toString()

    this.daysInLastMonth = []
    this.daysInCurrentMonth = []
    this.daysInNextMonth = []

    this._createArrayDaysInMonth(this.monthInNumber)
    this._createArrayDaysOutMonth(this.monthInNumber)
  }

  private _nextMonth() {
    if (this.monthInNumber !== 11) {
      this.monthInNumber++
    } else {
      this.monthInNumber = 0
      this.currentYear++
    }

    this.calendarChangeUpdate()
  }

  private _lastMonth() {
    if (this.monthInNumber !== 0) {
      this.monthInNumber--
    } else {
      this.monthInNumber = 11
      this.currentYear--
    }
    this.calendarChangeUpdate()
  }

  private _dropdowChangeMonth(month) {
    this.monthInNumber = month - 1
    this.calendarChangeUpdate()
  }

  private _dropdowChangeYear(year) {
    this.currentYear = year
    this.calendarChangeUpdate()
  }

  private clickDate(e: MouseEvent, day, month) {
    this.monthInNumber = month

    if (this.monthInNumber == -1) {
      this.monthInNumber = 11
      this.currentYear--
    } else if (month == 12) {
      this.monthInNumber = 0
      this.currentYear++
    }

    this.calendarChangeUpdate()
    this._bubbleEvent(e, day, this.monthInNumber)
  }

  private settingSelectedDate(day, month, year) {
    if (`${day}/${month}/${year}` === this.selectedDate) {
      return true
    } else {
      return false
    }
  }

  private _backToCurrentDate() {
    this.currentDay = new Date().getDate()
    this.monthInNumber = new Date().getMonth()
    this.currentYear = new Date().getFullYear()
    this.calendarChangeUpdate()
  }

  private _createArrayDaysInMonth(month) {
    dayjs.extend(weekday)

    const totalDayInCurrentMonth = dayjs(
      `${this.currentYear}-${month + 1}`
    ).daysInMonth()

    for (let i = 1; i <= totalDayInCurrentMonth; i++) {
      this.daysInCurrentMonth.push(i)
    }
  }

  private _createArrayDaysOutMonth(month) {
    dayjs.extend(weekday)

    const dayWeekStarts =
      dayjs(`${this.currentYear}-${month + 1}`).weekday() - 1

    const lastDaysInLastMonth = dayjs(
      `${this.currentYear}-${month}`
    ).daysInMonth()

    for (let i = 0; i <= dayWeekStarts; i++) {
      this.daysInLastMonth.push(lastDaysInLastMonth - i)
    }
    this.daysInLastMonth.sort((a, b) => a - b)

    const lastDayMonth = new Date(this.currentYear, month + 1, 0).getDay()
    if (6 - lastDayMonth > 0) {
      for (let i = 0; i < 6 - lastDayMonth; i++) {
        this.daysInNextMonth.push(i + 1)
      }
    }
  }

  private addingZero(number) {
    if (number <= 9) {
      return `0${number}`
    } else {
      return `${number}`
    }
  }

  private renderCalendarByPropSelectedDate() {
    if (this.selectedDate.length === 10) {
      const splitSelectedDate = this.selectedDate.split('')
      const monthInCalendar = splitSelectedDate[3] + splitSelectedDate[4]
      const yearInCalendar =
        splitSelectedDate[6] +
        splitSelectedDate[7] +
        splitSelectedDate[8] +
        splitSelectedDate[9]

      this.monthInNumber = Number(monthInCalendar) - 1
      this.currentYear = Number(yearInCalendar)
    }
  }

  private _renderWeek() {
    return html`<p>Dom</p>
      <p>Seg</p>
      <p>Ter</p>
      <p>Qua</p>
      <p>Qui</p>
      <p>Sex</p>
      <p>Sab</p> `
  }

  private _renderMonthAndYear() {
    return html`<clb-calendar-month
        month=${this.currentMonthName}
        .monthsArray=${this.monthsArray}
        @onClbSelectMonth=${(e) =>
          this._dropdowChangeMonth(e.detail.monthNumber)}
      ></clb-calendar-month>
      <clb-calendar-year
        year=${this.year}
        @onClbSelectYear=${(e) => this._dropdowChangeYear(e.detail.year)}
      ></clb-calendar-year>`
  }

  private _renderDaysInMonth() {
    const currentMonth = `${this.addingZero(this.monthInNumber + 1)}`
    const lastMonth = `${this.addingZero(this.monthInNumber)}`
    const nextMonth = `${this.addingZero(this.monthInNumber + 2)}`
    const year = `${this.currentYear}`

    return html`
      ${this.daysInLastMonth.map((day) => {
        const formattedDay = `${this.addingZero(day)}`
        return html`
          <clb-calendar-day
            id="${`${this.addingZero(day)}/${this.addingZero(
              this.monthInNumber
            )}/${this.currentYear}`}"
            day=${day}
            typeDay="in-last-month"
            ?selected=${this.settingSelectedDate(formattedDay, lastMonth, year)}
            @onClbSelectDay=${(e: MouseEvent) =>
              this.clickDate(e, day, this.monthInNumber - 1)}
          ></clb-calendar-day>
        `
      })}
      ${this.daysInCurrentMonth.map((day) => {
        const formattedDay = `${this.addingZero(day)}`

        return html`
          ${when(
            day === this.currentDay &&
              this.monthInNumber === new Date().getMonth() &&
              this.currentYear === new Date().getFullYear(),
            () => html`<clb-calendar-day
            id={${this.addingZero(day)}/${this.addingZero(
              this.monthInNumber + 1
            )}/${this.currentYear}}
            day=${day}
            isToday=${true}
            typeDay="in-current-month"
            tooltipLabel=${this.tooltipLabel}
            ?selected=${this.settingSelectedDate(
              formattedDay,
              currentMonth,
              year
            )}
            @onClbSelectDay=${(e: MouseEvent) =>
              this.clickDate(e, day, this.monthInNumber)}
          ></clb-calendar-day>`,
            () => html`<clb-calendar-day
            id=${this.addingZero(day)}/${this.addingZero(
              this.monthInNumber + 1
            )}/${this.currentYear}
            day=${day}
            typeDay="in-current-month"
            ?selected=${this.settingSelectedDate(
              formattedDay,
              currentMonth,
              year
            )}
            @onClbSelectDay=${(e: MouseEvent) =>
              this.clickDate(e, day, this.monthInNumber)}
          ></clb-calendar-day>`
          )}
        `
      })}
      ${this.daysInNextMonth.map((day) => {
        const formattedDay = `${this.addingZero(day)}`
        return html` <clb-calendar-day
          id="${`${this.addingZero(day)}/${this.addingZero(
            this.monthInNumber + 2
          )}/${this.currentYear}`}"
          day=${day}
          typeDay="in-next-month"
          ?selected=${this.settingSelectedDate(formattedDay, nextMonth, year)}
          @onClbSelectDay=${(e: MouseEvent) =>
            this.clickDate(e, day, this.monthInNumber + 1)}
        ></clb-calendar-day>`
      })}
    `
  }

  render() {
    return html`
      <div id="calendar" name=${this.name} class="calendar" tabindex="0">
        <div class="calendar__header">
          <div class="calendar__date" tabindex="0">
            <clb-icon
              icon="ChevronLeft"
              tabindex="0"
              @click=${() => this._lastMonth()}
            ></clb-icon>
            <div class="year-month">${this._renderMonthAndYear()}</div>
            <clb-icon
              icon="ChevronRight"
              tabindex="0"
              @click=${() => this._nextMonth()}
            ></clb-icon>
          </div>
          <div class="calendar__week" tabindex="0">${this._renderWeek()}</div>
        </div>
        <div id="calendarDays" class="calendar__days" tabindex="0">
          ${this._renderDaysInMonth()}
        </div>
        ${when(
          this.monthInNumber !== dayjs().get('month') ||
            this.currentYear !== dayjs().get('year'),
          () => html`
            <div class="calendar__back-current-day">
              <p @click=${() => this._backToCurrentDate()}>Hoje</p>
            </div>
          `
        )}
      </div>
    `
  }
}
