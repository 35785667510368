import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'

import { ClbMixin } from '@utils/ClbMixin'
import { dispatchCustomEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import '@components/dropdown'

import styles from './styles.scss'

@registerElement('clb-calendar-year')
export class ClbCalendarYear extends ClbMixin(LitElement) {
  @property({ type: String }) year
  @property({ type: Array }) yearsArray = []
  @property({ type: Boolean }) dropdownOpened = false

  static styles = [styles]

  static events = {
    onClbSelectYear: 'onClbSelectYear'
  }

  connectedCallback() {
    super.connectedCallback()
    this._createYearArray()
  }

  private _createYearArray = () => {
    const currentYear = new Date().getFullYear()

    for (let i = -5; i <= 5; i++) {
      this.yearsArray.push(currentYear + i)
    }
  }

  private _bubbleEvent(): void {
    dispatchCustomEvent({
      eventName: ClbCalendarYear.events.onClbSelectYear,
      eventOptions: {
        cancelable: true,
        detail: {
          year: this.year,
          dropdownOpened: this.dropdownOpened
        }
      },
      dispatcher: this
    })
  }

  private _changeYear(year) {
    for (const singleYear of this.yearsArray) {
      if (singleYear === year) {
        this.year = year
      }
    }

    this._bubbleEvent()
  }

  render() {
    return html`<clb-dropdown
      anchorSelector="#dropdown-trigger"
      ?open=${this.dropdownOpened}
      @click=${() => (this.dropdownOpened = !this.dropdownOpened)}
    >
      <div id="dropdown-trigger" slot="dropdown-trigger">
        <p>${this.year}</p>
        <clb-icon icon="ChevronDown" size="sm" />
      </div>
      ${this.yearsArray.map(
        (year) => html` <clb-dropdown-item
          id="${`year${year}`}"
          slot="dropdown-content"
          @click=${() => this._changeYear(year)}
        >
          <clb-text>${year}</clb-text>
        </clb-dropdown-item>`
      )}
    </clb-dropdown>`
  }
}
