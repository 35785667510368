import {css} from 'lit';
export const styles = css`.link {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-default);
  position: relative;
  text-decoration: none;
  border: var(--border-width-sm) solid transparent;
  padding-top: var(--spacing-size-5xs);
  padding-bottom: var(--spacing-size-5xs);
  box-sizing: border-box;
  width: fit-content;
  align-items: center;
  color: var(--color-neutral-600);
}
.link:after {
  content: "";
  position: absolute;
  right: 0rem;
  bottom: 0rem;
  height: var(--border-radius-sm);
  width: var(--line-height-default);
  background-color: var(--color-neutral-600);
  transition-property: width;
  transition-duration: var(--velocity-slow);
  transition-timing-function: var(--vibe-soft);
  border-radius: var(--border-radius-sm);
}
.link:hover {
  text-decoration: none;
}
.link:hover::after {
  width: 50%;
}
.link:focus:not(:focus-visible) {
  outline: transparent;
}
.link:focus-visible {
  outline: transparent;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 0 0 2px var(--color-contrast-focused);
}
.link--disabled {
  pointer-events: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: var(--color-neutral-300) !important;
}
.link--disabled::after {
  background-color: var(--color-neutral-300) !important;
}
.link--disabled:hover::after {
  width: 100% !important;
}`;
export default styles;
