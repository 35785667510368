import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'

import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-radio-group')
export class ClbRadioGroup extends ClbMixin(LitElement) {
  @property({ type: String }) orientation = 'list'

  static styles = [styles]

  protected firstUpdated(): void {
    if (this.orientation == 'line') {
      this.parentElement.style.display = 'flex'
      this.parentElement.style.gap = '24px'
    }
  }

  render() {
    return html`
      <div class="RadioGroup" orientation="${this.orientation}">
        <slot></slot>
      </div>
    `
  }
}
