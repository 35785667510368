import { html, LitElement } from 'lit'

import styles from '@components/table/styles.scss'

import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

@registerElement('clb-table-tbody')
export class ClbTableTBody extends ClbMixin(LitElement) {
  static styles = [styles]

  render() {
    return html` <slot></slot> `
  }
}
