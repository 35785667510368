import { html, LitElement, PropertyValues } from 'lit'
import { property, state } from 'lit/decorators.js'

import { monthsArray } from '@components/calendar-month/props.types'
import { Sizes } from '@components/input-base/props.types'

import { ClbMixin } from '@utils/ClbMixin'
import { dispatchCustomEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import '@components/calendar-input'
import '@components/calendar'

import styles from './styles.scss'

@registerElement('clb-date-picker')
export class ClbDatePicker extends ClbMixin(LitElement) {
  @property({ type: String }) id = ''
  @property({ type: String }) label = 'Label'
  @property({ type: String }) placeholder = '00/00/0000'
  @property({ type: String }) value = ''
  @property({ attribute: 'helper-text', type: String }) helperText = ''
  @property({ type: String }) requiredMessage = 'obrigatório'
  @property({ type: String }) optionalMessage = 'opcional'
  @property({ type: String }) name = ''
  @property({ type: Boolean }) showMessage = false
  @property({ type: Boolean }) showHelperText = false
  @property({ type: String }) size: Sizes = 'lg'
  @property({ type: Boolean }) required = false
  @property({ type: Boolean }) disabled = false
  @property({ type: Boolean }) error = false
  @property({ type: Boolean }) calendarOpened = false
  @property({ type: String }) tooltipLabel = 'Data atual'
  @property({ type: Array }) monthsArray = monthsArray

  @state() typedValue = ''
  @state() selectedDate = ''
  @state() currentMonthName = ''
  @state() year = ''

  monthInNumber = new Date().getMonth()
  currentYear = new Date().getFullYear()
  clbCalendar: any

  static styles = [styles]

  static events = {
    onClbChangeDate: 'onClbChangeDate'
  }

  protected updated(changedProps: PropertyValues): void {
    if (changedProps.has('value')) {
      this.changeCalendarByPropValue()
    }
  }

  protected firstUpdated() {
    this.clbCalendar = this.shadowRoot.querySelector('clb-calendar')
    this.clbCalendar.classList.add(
      this.calendarOpened ? 'calendar-opened' : 'calendar-closed'
    )
  }

  private openAndCloseCalendar() {
    this.calendarOpened = !this.calendarOpened

    if (this.calendarOpened) {
      this.clbCalendar.classList.remove('calendar-closed')
      this.clbCalendar.classList.add('calendar-opened')
      this.showHelperText = false
    } else {
      this.clbCalendar.classList.remove('calendar-opened')
      this.clbCalendar.classList.add('calendar-closed')
      this.showHelperText = true
    }

    this.closeCalendarClickOutside()
    this.closeCalendarClickIcon()
  }

  private closeCalendar() {
    this.calendarOpened = false
    this.clbCalendar.classList.remove('calendar-opened')
    this.clbCalendar.classList.add('calendar-closed')
    this.showHelperText = true
  }

  private closeCalendarClickOutside() {
    const inputDiv = this.shadowRoot
      .querySelector('clb-calendar-input')
      .shadowRoot.querySelector('.input-container')

    document.body.addEventListener('click', (event: MouseEvent) => {
      const path = event.composedPath()

      if (!path.includes(inputDiv) && !path.includes(this.clbCalendar)) {
        this.closeCalendar()
      }
    })
  }

  private closeCalendarClickIcon() {
    const icon = this.shadowRoot
      .querySelector('clb-calendar-input')
      .shadowRoot.querySelector('.input-container').lastElementChild

    icon.addEventListener('click', () => {
      this.closeCalendar()
      this.selectedDate = ''
    })
  }

  private _bubbleEvent(evt: CustomEvent): void {
    const targetElement = this.shadowRoot
      .querySelector('clb-calendar-input')
      .shadowRoot.querySelector('input')

    dispatchCustomEvent({
      eventName: ClbDatePicker.events.onClbChangeDate,
      eventOptions: {
        cancelable: true,
        detail: {
          value: evt.detail.value
        }
      },
      dispatcher: this,
      targetElement
    })
  }

  private changeCalendarDate(data) {
    this._bubbleEvent(data)
    this.typedValue = data.detail.value

    if (this.typedValue.length === 10) {
      this.selectedDate = this.typedValue

      const splitTypedValue = this.typedValue.split('')

      const typedMonth = splitTypedValue[3] + splitTypedValue[4]
      const typedYear =
        splitTypedValue[6] +
        splitTypedValue[7] +
        splitTypedValue[8] +
        splitTypedValue[9]

      this.monthInNumber = Number(typedMonth) - 1
      this.currentMonthName = this.monthsArray[Number(typedMonth) - 1]
      this.currentYear = Number(typedYear)
      this.year = typedYear
    }
  }

  private changeValue(data: CustomEvent) {
    this.selectedDate = data.detail.value

    const inputDiv = this.shadowRoot
      .querySelector('clb-calendar-input')
      .shadowRoot.querySelector('input')

    inputDiv.value = this.selectedDate

    this.calendarOpened = false
    this.clbCalendar.classList.remove('calendar-opened')
    this.clbCalendar.classList.add('calendar-closed')
    this.showHelperText = true
    this._bubbleEvent(data)
  }

  private changeCalendarByPropValue() {
    if (this.value?.length === 10) {
      const splitValue = this.value.split('')
      const daysInCalendar = splitValue[0] + splitValue[1]
      const monthInCalendar = splitValue[3] + splitValue[4]
      const yearInCalendar =
        splitValue[6] + splitValue[7] + splitValue[8] + splitValue[9]

      this.monthInNumber = Number(monthInCalendar) - 1
      this.currentMonthName = this.monthsArray[Number(monthInCalendar) - 1]
      this.currentYear = Number(yearInCalendar)
      this.year = yearInCalendar
      this.selectedDate = `${daysInCalendar}/${monthInCalendar}/${yearInCalendar}`
    }
  }

  render() {
    return html` <div>
      <clb-calendar-input
        id=${this.id}
        label=${this.label}
        name=${this.name}
        .value=${this.value}
        helperText=${this.helperText}
        requiredMessage=${this.requiredMessage}
        optionalMessage=${this.optionalMessage}
        ?showMessage=${this.showMessage}
        ?showHelperText=${this.showHelperText}
        ?required=${this.required}
        ?error=${this.error}
        ?disabled=${this.disabled}
        size=${this.size}
        @onClbChangeInputDate=${this.changeCalendarDate}
        @onClbClearInputDate=${this.changeCalendarDate}
        @onClbClickInputDate=${this.openAndCloseCalendar}
      ></clb-calendar-input>
      <clb-calendar
        name=${this.name}
        .monthsArray=${this.monthsArray}
        tooltipLabel=${this.tooltipLabel}
        selectedDate="${this.selectedDate}"
        .monthInNumber=${this.monthInNumber}
        currentMonthName=${this.currentMonthName}
        .currentYear=${this.currentYear}
        year=${this.year}
        @onClbSelectDate=${this.changeValue}
      ></clb-calendar>
    </div>`
  }
}
