import {css} from 'lit';
export const styles = css`.radio-label[disabled=true] span {
  color: var(--color-neutral-300);
  pointer-events: none;
}

.radio-label[disabled=true] span.button-icon::before {
  background: var(--color-neutral-300);
  opacity: var(--opacity-level-3);
}

.radio-label[disabled=true] :checked + span.button-icon::before {
  border-color: var(--color-neutral-300);
  opacity: var(--opacity-level-3);
}

.container {
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.container label {
  margin: 0px;
}

.radio-input {
  appearance: none;
  z-index: -1;
  position: absolute;
  display: block;
  margin: 0px;
  box-shadow: none;
  outline: currentcolor none medium;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s ease 0s, transform 0.2s ease 0s;
  width: 32px;
  height: 32px;
  left: -8px;
  top: -8px;
  border-radius: var(--border-radius-circle);
  border: 8px solid var(--color-neutral-300);
}
.radio-input:focus-visible + span.button-icon::after {
  content: "";
  border: 2px solid var(--color-contrast-focused);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: -4px;
  position: absolute;
}

.radio-input:checked + span.button-icon::before {
  border: 5px solid var(--color-action-default);
  background-color: var(--color-contrast-brand);
}

.radio-label span {
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  cursor: pointer;
  overflow-y: hidden;
  color: var(--color-neutral-600);
  font-family: var(--font-family-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
}

.radio-label span.button-icon::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 0px var(--spacing-size-4xs) 0px 0px;
  border: var(--border-width-default) solid var(--color-neutral-300);
  border-radius: 100%;
  vertical-align: top;
  height: 16px;
  width: 16px;
  transition-property: border;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.64, 0, 0.92, 1);
}

.radio-label[disabled=false] {
  position: relative;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
}
.radio-label[disabled=false]:hover .radio-input:not(:checked) + span.button-icon::before {
  border: var(--border-width-default) solid var(--color-action-default);
  background: var(--color-action-hover);
  opacity: var(--opacity-level-3);
}

label > input[type=radio]:checked + * {
  font-weight: var(--font-weight-semibold);
}

.hidden-label {
  z-index: -9999;
  position: absolute;
  visibility: hidden;
}`;
export default styles;
