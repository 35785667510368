import { html, LitElement } from 'lit'

import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-toast')
export class ClbToast extends ClbMixin(LitElement) {
  static styles = [styles]

  render() {
    return html`<div class="toast"><slot></slot></div>`
  }
}
