import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'

import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

import { Sizes, Roles } from './props.types'
import styles from './styles.scss'

@registerElement('clb-heading')
export class ClbHeading extends ClbMixin(LitElement) {
  @property({ type: String }) size: Sizes = '2xl'

  @property({ type: String }) displayRole: Roles = 'h1'

  static styles = [styles]

  private get headingHierarchyTag() {
    switch (this.displayRole) {
      case 'h1':
        return html`
          <h1 class="heading heading--${this.size}">
            <slot></slot>
          </h1>
        `
      case 'h3':
        return html`
          <h3 class="heading heading--${this.size}">
            <slot></slot>
          </h3>
        `
      case 'h4':
        return html`
          <h4 class="heading heading--${this.size}">
            <slot></slot>
          </h4>
        `
      case 'h5':
        return html`
          <h5 class="heading heading--${this.size}">
            <slot></slot>
          </h5>
        `
      case 'h6':
        return html`
          <h6 class="heading heading--${this.size}">
            <slot></slot>
          </h6>
        `
      default:
        return html`
          <h2 class="heading heading--${this.size}">
            <slot></slot>
          </h2>
        `
    }
  }

  render() {
    return html` ${this.headingHierarchyTag} `
  }
}
