import {css} from 'lit';
export const styles = css`:host(clb-table-container) .overflow-table {
  overflow: auto;
}
:host(clb-table-container) .overflow-table::-webkit-scrollbar {
  width: var(--border-width-xl);
  height: var(--border-width-xl);
  border-radius: var(--border-radius-default);
  background: var(--color-neutral-200);
}
:host(clb-table-container) .overflow-table::-webkit-scrollbar-thumb {
  background: var(--color-neutral-400);
  border-radius: var(--border-radius-default);
}
:host(clb-table-container) .table-loading {
  max-height: 350px !important;
}
:host(clb-table-container) .loading {
  padding: var(--spacing-size-3xl) 0px;
}

:host(clb-table) {
  display: table;
  width: 100%;
  min-width: 100%;
}

:host(clb-table-tbody-row:nth-child(even)) {
  background-color: var(--color-neutral-background);
}

:host(clb-table-tbody-row) {
  background-color: var(--color-neutral-100);
}

:host(clb-table-thead) {
  display: table-header-group;
  vertical-align: middle;
  position: sticky;
  top: 0;
  z-index: 1;
}

:host(clb-table-tbody) {
  display: table-row-group;
  vertical-align: middle;
}

:host(clb-table-thead-row),
:host(clb-table-tbody-row) {
  display: table-row;
  vertical-align: inherit;
}

:host(clb-table-thead-cell),
:host(clb-table-tbody-cell) {
  display: table-cell;
  vertical-align: middle;
}

:host(clb-table-thead-cell) {
  height: 64px;
  background-color: var(--color-neutral-200);
  padding: 0px var(--spacing-size-3xs);
  color: var(--color-neutral-600);
  font-size: var(--font-size-sm);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-default);
  text-align: left;
}
:host(clb-table-thead-cell) .thead-wrapper {
  display: inline-flex;
  align-items: center;
}
:host(clb-table-thead-cell) .thead-wrapper clb-button-icon {
  margin-left: var(--spacing-size-5xs);
}

:host(clb-table-tbody-cell) {
  height: 48px;
  padding: 0px var(--spacing-size-3xs);
  color: var(--color-neutral-600);
  font-size: var(--font-size-sm);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
  text-align: left;
}

:host(clb-table-footer) {
  width: 100%;
  color: var(--color-neutral-600);
  font-size: var(--font-size-sm);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
  text-align: left;
}
:host(clb-table-footer) .tfooter-wrapper {
  background-color: var(--color-neutral-200);
  padding: 0 var(--spacing-size-3xs);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
}

:host(clb-table-empty) {
  font-family: var(--font-family-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-size-3xl) 0px;
}
:host(clb-table-empty) clb-icon {
  margin-bottom: 0.7rem;
}

clb-table-thead-cell.thead-checkbox,
clb-table-tbody-cell.tcell-checkbox {
  padding-left: var(--spacing-size-xs);
  width: 16px;
}

:host(clb-table-toolbar) {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--spacing-size-xs);
  margin-bottom: var(--spacing-size-sm);
  padding: 0px var(--spacing-size-xs);
}
:host(clb-table-toolbar) .toolbar-buttons {
  display: flex;
  flex-wrap: wrap;
}
:host(clb-table-toolbar) .toolbar-buttons ::slotted(clb-button), :host(clb-table-toolbar) .toolbar-buttons ::slotted(clb-button-icon) {
  margin-right: var(--spacing-size-3xs);
}

@media only screen and (max-width: 450px) {
  .toolbar-search {
    width: 100%;
  }
}`;
export default styles;
