import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'

import '@components/dropdown'
import '@components/dropdown-item'

import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

import { Sizes } from './props.types'
import styles from './styles.scss'

@registerElement('clb-avatar')
export class ClbAvatar extends ClbMixin(LitElement) {
  @property({ type: String }) label = ''

  @property({ type: String }) size: Sizes = 'md'

  @property({ type: String }) url = ''

  @property({ type: Boolean }) image = false

  @property({ type: Boolean }) open = false

  @property({ type: Boolean }) isLink = true

  static styles = [styles]

  get patternLabel() {
    return this.label[0] ? this.label[0].toLocaleUpperCase() : ''
  }

  verifyImg(src: any, success: any, error: any) {
    const img = new Image()
    img.onload = success
    img.onerror = error
    img.src = src
  }

  render() {
    if (this.url != '') {
      this.verifyImg(
        this.url,
        () => false,
        () => {
          console.error('Erro na imagem do componente Avatar!')
          this.url = ''
        }
      )
    }

    return html`
      <div class="avatar avatar--${this.size}">
        ${this.image && this.url
          ? html`<img alt="${this.label}" src="${this.url}" />`
          : html`<span class="avatar__label">${this.patternLabel}</span>`}
      </div>
    `
  }
}
