import {css} from 'lit';
export const styles = css`.input {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: var(--font-family-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-regular);
}
.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  background-color: var(--color-opacity-default);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) solid var(--color-neutral-300);
  margin-top: var(--spacing-size-4xs);
  margin-bottom: var(--spacing-size-4xs);
}
.input-container input {
  width: 100%;
  border: transparent;
  outline: transparent;
  background-color: transparent;
  font-family: var(--font-family-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-regular);
  color: var(--color-neutral-600);
}
.input-container input::placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-neutral-300);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
}
.input-container input:hover {
  border: transparent;
  background-color: transparent;
}
.input-container input:focus {
  outline: transparent;
  border-color: transparent;
}
.input-container input:focus-visible {
  outline: transparent;
  border-color: transparent;
}
.input-container:hover {
  border: var(--border-width-default) solid var(--color-brand-300);
  background-color: var(--color-opacity-hover);
}
.input-container:hover::placeholder {
  color: var(--color-contrast-brand);
}
.input-container i {
  position: relative;
  padding: 0 var(--spacing-size-3xs);
  cursor: pointer;
}
.input-container i svg {
  width: 21px;
  height: 21px;
}
.input-container--disabled {
  background: var(--color-opacity-disabled);
}
.input-container--disabled:hover {
  background: var(--color-opacity-disabled);
  border: var(--border-width-default) solid var(--color-neutral-300);
}
.input-container--disabled input {
  color: var(--color-neutral-300);
  border: none;
  outline: none;
}
.input-container--disabled input::placeholder {
  color: var(--color-neutral-300);
}
.input-container--disabled input:hover {
  border: none !important;
  outline: none;
  background-color: transparent;
}
.input-container--disabled input:hover::placeholder {
  color: var(--color-neutral-300);
}
.input-container--disabled svg {
  color: var(--color-neutral-300);
  cursor: auto;
}
.input-container--add-outline {
  border-color: var(--color-brand-300);
  outline: 2px solid var(--color-contrast-focused);
  outline-offset: 2px;
}

.hiddenCloseIcon {
  visibility: hidden;
}

.showCloseIcon {
  visibility: visible;
}`;
export default styles;
