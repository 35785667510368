import {css} from 'lit';
export const styles = css`.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
  color: var(--color-neutral-600);
}
.pagination--sm {
  justify-content: center;
}
.pagination--md {
  justify-content: center;
}

.select {
  margin-top: 8px;
  display: inline-flex;
}
.select clb-text {
  margin-left: 8px;
  margin-top: 2.8%;
  margin-right: 15px;
}
.select--sm {
  display: none;
}

.result--lg {
  display: flex;
  align-items: flex-start !important;
  justify-content: right !important;
  margin-left: 15px;
}
.result--md {
  margin-left: 15px;
}
.result--sm {
  display: none;
}
.result--disabled {
  color: var(--color-neutral-300) !important;
}
.result--disabled clb-text {
  color: inherit !important;
  pointer-events: none;
  background: none !important;
}

.numbers {
  display: flex;
  justify-content: center;
  align-items: center;
}
.numbers button {
  background: none;
  border: none;
  padding: 0 5px 0 5px;
  font: inherit;
}
.numbers .button-active {
  background-color: var(--color-action-default);
  font-weight: bold;
  border-radius: 4px;
  color: var(--color-contrast-brand);
}
.numbers .button-active--disabled {
  pointer-events: none;
  color: var(--color-neutral-300) !important;
  background: none !important;
}
.numbers button, .numbers clb-button-icon {
  cursor: pointer;
  display: flex;
  min-width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  margin: 0 var(--spacing-inset-size-2xs);
  color: var(--color-contrast-600);
}
.numbers button:hover, .numbers clb-button-icon:hover {
  background-color: var(--color-action-hover);
  border-radius: 4px;
}
.numbers button:active, .numbers clb-button-icon:active {
  font-weight: var(--font-weight-bold);
  background-color: var(--color-action-default);
  border-radius: 4px;
}
.numbers .button-dropdown--right, .numbers .button-dropdown--left {
  cursor: pointer;
  display: flex;
  width: 220px;
  height: 36px;
  align-items: center;
  justify-content: center;
  margin: 0px;
}
.numbers .button-dropdown--right:hover, .numbers .button-dropdown--left:hover {
  background-color: var(--color-action-hover);
  border-radius: none;
}
.numbers .button-dropdown--right:active, .numbers .button-dropdown--left:active {
  font-weight: var(--font-weight-bold);
  background-color: var(--color-action-default);
  border-radius: 4px;
}

.number--disabled {
  font-weight: none !important;
  pointer-events: none;
  color: var(--color-neutral-300) !important;
  background: none !important;
}

.dropdown--disabled {
  pointer-events: none;
  color: var(--color-neutral-300) !important;
  background: none !important;
}

clb-button {
  margin-left: 4px;
  margin-right: 4px;
}
clb-button:active {
  font-weight: var(--font-weight-bold);
  background-color: var(--color-action-default);
  border-radius: 4px;
}
clb-button:hover {
  background-color: none !important;
}`;
export default styles;
