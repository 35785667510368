import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { when } from 'lit/directives/when.js'

import '@components/icon'
import '@components/link'
import '@components/button-icon'

import { ClbMixin } from '@utils/ClbMixin'
import { bubbleEvent, createEvent, dispatchCustomEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import { Types, typesArray, TypesIcon, ClbLinkPros } from './props.types'
import styles from './styles.scss'

@registerElement('clb-toast-base')
export class ClbToastBase extends ClbMixin(LitElement) {
  @property({ type: String }) type: Types = 'positive'

  @property({ type: String }) title = ''

  @property({ type: String }) description = ''

  @property({ type: Object }) link: ClbLinkPros = { title: '', href: '' }

  @property({ type: Boolean }) open = false

  @property({ type: String, state: true }) protected _icon: TypesIcon = 'Check'

  @property({ type: Number, state: true }) protected _closeTime = 10000

  @property({ type: Number, state: true }) protected _animationTime = 400

  static styles = [styles]

  static events = {
    onClbClickToastClose: 'onClbClickToastClose',
    onClbClickToastLink: 'onClbClickToastLink'
  }

  private get getRole() {
    return this.link?.title ? 'alertdialog' : 'alert'
  }

  private get hasDescription() {
    return html`
      ${when(
        this.description,
        () =>
          html`<p id="toast-base-content__description">${this.description}</p>`,
        () => null
      )}
    `
  }

  private get hasLink() {
    return html`
      ${when(
        !!this.link?.title,
        () => html` <clb-link
          href=${this.link.href}
          hreflang=${this.link?.hreflang || 'x-default'}
          rel=${this.link?.rel || 'nofollow'}
          target=${this.link?.target || '_self'}
          ?disabled="${!!this.link?.disabled}"
          ?download="${!!this.link?.download}"
          ?isSpa="${!!this.link?.isSpa}"
          @onClbClick=${(e) => this.navLink(e)}
        >
          ${this.link.title}
        </clb-link>`,
        () => null
      )}
    `
  }

  private get patternType(): Types {
    switch (this.type) {
      case 'negative':
        this._icon = 'Spam'
        break
      case 'warning':
        this._icon = 'Warning'
        break
      case 'positive':
        this._icon = 'Check'
        break
    }
    return typesArray.includes(this.type) ? this.type : 'positive'
  }

  _dispatchEvent() {
    dispatchCustomEvent({
      eventName: ClbToastBase.events.onClbClickToastClose,
      eventOptions: {
        detail: {
          open: this.open
        }
      },
      dispatcher: this
    })
  }

  private removeNode() {
    setTimeout(() => {
      if (this.open) {
        this.open = false
        this._dispatchEvent()
      }
    }, this._closeTime)
  }

  private navLink(evt: Event): void {
    const event = createEvent(ClbToastBase.events.onClbClickToastLink, {
      cancelable: true
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)
    this.dispatchEvent(event)
  }

  private handleClose() {
    this.open = false
    this._dispatchEvent()
  }

  private hasAttributeDescription(_description: string | boolean) {
    const toast: Element = this.shadowRoot.querySelector('.toast-base')

    if (toast) {
      if (_description) {
        toast.setAttribute(
          'aria-describedby',
          'toast-base-content__description'
        )
      } else {
        toast.removeAttribute('aria-describedby')
      }
    }
  }

  protected firstUpdated(): void {
    this.hasAttributeDescription(this.description)
  }

  render() {
    if (this.open) {
      this.removeNode()
    }
    return html`<div
      class="
      ${classMap({
        [`toast-base`]: true,
        [`toast-base--active`]: this.open
      })}"
      aria-labelledby="toast-base-content__label"
      role=${this.getRole}
      tabindex="0"
    >
      <div
        class="
      ${classMap({
          [`toast-base__icon`]: true,
          [`toast-base__icon--${this.patternType}`]: true
        })}"
      >
        <clb-icon
          class="${classMap({
            [`toast-base__icon-position`]: true,
            [`toast-base__icon-position--warning`]: this._icon === 'Warning'
          })}"
          icon="${this._icon}"
        ></clb-icon>
      </div>
      <div class="toast-base-content">
        <h2 id="toast-base-content__label">${this.title}</h2>
        ${this.hasDescription}${this.hasLink}
      </div>
      <div class="toast-base-action">
        <clb-button-icon
          @onClbClick=${() => this.handleClose()}
          icon="Close"
        ></clb-button-icon>
      </div>
    </div>`
  }
}
