import {css} from 'lit';
export const styles = css`.tag {
  border-radius: var(--border-radius-pill);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: var(--spacing-size-3xs);
  padding-right: var(--spacing-size-3xs);
  border: transparent;
  position: relative;
  display: inline-flex;
  text-align: center;
  max-height: 16px;
  gap: 5px;
}
.tag:focus-visible {
  outline: none;
}
.tag--filter {
  color: var(--color-neutral-600);
  background-color: var(--color-opacity-default);
  border: var(--border-width-default) solid var(--color-neutral-200);
  cursor: pointer;
}
.tag--filter:focus-visible {
  outline: 2px solid var(--color-contrast-focused);
  outline-offset: 2px;
}
.tag--filter:hover {
  background-color: var(--color-action-hover);
  border-color: var(--color-action-hover);
  color: var(--color-brand-contrast);
}
.tag--filter .icon--custom {
  display: none;
}
.tag--neutral {
  color: var(--color-neutral-600);
  background-color: var(--color-neutral-200);
}
.tag--neutral .icon--custom {
  display: none;
}
.tag--negative {
  color: var(--color-feedback-danger-600);
  background-color: var(--color-feedback-danger-100);
}
.tag--warning {
  color: var(--color-feedback-warning-600);
  background-color: var(--color-feedback-warning-100);
}
.tag--positive {
  color: var(--color-feedback-positive-600);
  background-color: var(--color-feedback-positive-100);
}

.label-tag {
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-default);
  height: 16px;
}

.remove-tag {
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  height: 16px;
  width: 16px;
  padding: 0;
}`;
export default styles;
