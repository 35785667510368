import {css} from 'lit';
export const styles = css`.avatar {
  box-sizing: border-box;
  background-color: var(--color-brand-200);
  border: var(--border-width-default) solid var(--color-neutral-100);
  box-shadow: var(--shadow-level-1) rgba(0, 0, 0, 0.08);
  border-radius: var(--border-radius-circular);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  border-radius: var(--border-radius-circular);
  background-color: var(--color-neutral-background);
  border: var(--border-width-default) solid var(--color-neutral-100);
}
.avatar--sm {
  width: 32px;
  height: 32px;
}
.avatar--sm .avatar__label {
  font-size: var(--font-size-xs);
}
.avatar--md {
  width: 48px;
  height: 48px;
}
.avatar--md .avatar__label {
  font-size: var(--font-size-sm);
}
.avatar--lg {
  width: 64px;
  height: 64px;
}
.avatar--lg .avatar__label {
  font-size: var(--font-size-default);
}
.avatar__label {
  color: var(--color-contrast-brand);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-xs);
}

#avatarMenuLink {
  color: var(--color-neutral-600);
  font-size: var(--font-size-default);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
  text-decoration: none;
}`;
export default styles;
