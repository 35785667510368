import { html, LitElement, PropertyValues } from 'lit'
import { property } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'

import { ButtonAttributes } from '@components/button-group/props.types'
import '@components/heading'
import '@components/button-icon'
import '@components/text'
import '@components/button-group'

import { ClbMixin } from '@utils/ClbMixin'
import { bubbleEvent, createEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-drawer')
export class ClbDrawer extends ClbMixin(LitElement) {
  @property({ type: String }) title = ''

  @property({ type: String }) description = ''

  @property({ type: Boolean }) responsive = false

  @property({ type: Boolean }) opened = false

  @property({ type: Array }) buttonsList: Array<ButtonAttributes> = []

  @property({ attribute: 'aria-labelledby', type: String }) ariaLabelledBy = ''

  static styles = [styles]

  static events = {
    onClbClose: 'onClbClose',
    onClbHaveOpened: 'onClbHaveOpened',
    onClbHaveClosed: 'onClbHaveClosed'
  }

  protected override updated(changedProperties: PropertyValues): void {
    if (changedProperties.get('opened') !== undefined) {
      this._emitVisibilityChange()
    }
  }

  private _emitVisibilityChange() {
    const details = {
      bubbles: true,
      composed: true
    }
    if (this.opened) {
      this.dispatchEvent(
        new CustomEvent(ClbDrawer.events.onClbHaveOpened, details)
      )
    } else {
      this.dispatchEvent(
        new CustomEvent(ClbDrawer.events.onClbHaveClosed, details)
      )
    }
  }

  private getClassAttributes = (): ClassInfo => ({
    ['drawer']: true,
    ['drawer--opened']: this.opened,
    ['drawer--responsive']: this.responsive
  })

  getOrientation() {
    return this.responsive ? 'list' : 'line'
  }
  _handleClick(evt: Event) {
    const event = createEvent(ClbDrawer.events.onClbClose, {
      cancelable: true
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)
    this.dispatchEvent(event)
  }

  render() {
    return html`
      <div
        class=${classMap({
          ['drawer__fadein']: this.opened
        })}
      >
        <aside
          tabIndex=${this.tabIndex}
          class=${classMap(this.getClassAttributes())}
          aria-expanded=${this.opened}
          aria-label="texto"
          aria-labelledby="${this.ariaLabelledBy}"
        >
          <div class="drawer__content">
            <div class="drawer__header">
              <clb-heading class="drawer__heading" size="xs"
                >${this.title}</clb-heading
              >
              <clb-button-icon
                class="drawer__close"
                icon="Close"
                size="lg"
                @click=${this._handleClick}
              >
              </clb-button-icon>
            </div>
            <clb-text>${this.description}</clb-text>
            <slot></slot>
          </div>
          <div class="drawer__footer">
            <clb-button-group
              class=${classMap({
                'drawer__buttonGroup-responsive': this.responsive
              })}
              type="cancel"
              size="md"
              orientation="${this.getOrientation()}"
              .buttonsList=${this.buttonsList}
            ></clb-button-group>
          </div>
        </aside>
      </div>
    `
  }
}
