import {css} from 'lit';
export const styles = css`:host {
  display: inline-block;
  width: 100%;
}

.input-base {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-regular);
  color: var(--color-neutral-600);
  line-height: var(--line-height-default);
  text-align: left;
}
.input-base-label {
  display: inline-flex;
  flex-direction: row;
  gap: var(--spacing-size-5xs);
  font-weight: var(--font-weight-semibold);
}
.input-base-label--message {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}
.input-base-label--disabled {
  color: var(--color-neutral-300) !important;
}
.input-base-label--sm {
  display: none;
}
.input-base-input {
  font-family: var(--font-family-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-regular);
  color: var(--color-neutral-600);
  line-height: var(--line-height-default);
  text-align: left;
  min-width: 280px;
  width: 100%;
  margin-top: var(--spacing-size-4xs);
  margin-bottom: var(--spacing-size-4xs);
  padding-left: var(--spacing-size-3xs);
  padding-right: var(--spacing-size-3xs);
  box-sizing: border-box;
  background-color: var(--color-opacity-default);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) solid var(--color-neutral-300);
}
.input-base-input--sm {
  height: 36px;
}
.input-base-input--lg {
  height: 48px;
}
.input-base-input--error {
  background-color: var(--color-opacity-error);
  border-color: var(--color-feedback-danger-300) !important;
  color: var(--color-feedback-danger-300) !important;
}
.input-base-input--error::placeholder {
  color: var(--color-feedback-danger-300) !important;
}
.input-base-input::placeholder {
  font-size: var(--font-size-default);
  line-height: var(--font-size-xs);
  color: var(--color-neutral-300);
  opacity: unset;
  overflow: visible;
}
.input-base-input:hover:not(:disabled):not(.input-base-input--error) {
  background-color: var(--color-opacity-hover);
  border-color: var(--color-brand-300);
}
.input-base-input:hover:not(:disabled):not(.input-base-input--error)::placeholder {
  color: var(--color-neutral-600);
}
.input-base-input:focus {
  outline: 2px solid var(--color-contrast-focused);
  outline-offset: 2px;
  border-radius: var(--border-radius-default);
  border-color: var(--color-brand-300);
  border-width: var(--border-width-default);
}
.input-base-input:focus-visible {
  outline: 2px solid var(--color-contrast-focused);
  outline-offset: 2px;
  border-color: var(--color-brand-300);
  border-width: var(--border-width-default);
}
.input-base-input:disabled {
  background: var(--color-opacity-disabled) !important;
  border-color: var(--color-neutral-300) !important;
}
.input-base-input:disabled::placeholder {
  color: var(--color-neutral-300) !important;
}
.input-base-helper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-size-5xs);
  font-size: var(--font-size-sm);
}
.input-base-helper span {
  display: none;
}
.input-base-helper--error {
  color: var(--color-feedback-danger-300);
}
.input-base-helper--error span {
  display: inline-block;
  height: var(--font-size-default);
}
.input-base-helper--error span svg {
  height: var(--font-size-default);
  width: var(--font-size-default);
}`;
export default styles;
