import {css} from 'lit';
export const styles = css`.icon {
  display: inline-block;
  color: inherit;
}
.icon--sm {
  height: var(--font-size-default);
  width: var(--font-size-default);
}
.icon--md {
  height: var(--font-size-xl);
  width: var(--font-size-xl);
}
.icon--lg {
  height: var(--font-size-2xl);
  width: var(--font-size-2xl);
}
.icon--base {
  height: var(--font-size-lg);
  width: var(--font-size-lg);
}
.icon--neutral {
  color: var(--color-neutral-600);
}
.icon--negative {
  color: var(--color-feedback-danger-400);
}
.icon--warning {
  color: var(--color-feedback-warning-400);
}
.icon--positive {
  color: var(--color-feedback-positive-400);
}
.icon--disabled {
  color: var(--color-neutral-300);
}
.icon--white {
  color: var(--color-contrast-white);
}
.icon--hover:hover {
  color: var(--color-contrast-black);
}
.icon svg {
  width: 100%;
  height: 100%;
}`;
export default styles;
