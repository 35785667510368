import { LitElement, html } from 'lit'
import { property, state } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'

import { icons } from '@celebration/assets/dist'

import { ClbMixin } from '@utils/ClbMixin'
import { dispatchCustomEvent, bubbleEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-input-search')
export class ClbInputSearch extends ClbMixin(LitElement) {
  @property({ type: String }) label = ''
  @property({ type: String }) placeholder = ''
  @property({ type: String }) value = ''
  @property({ type: String }) name = ''
  @property({ type: String }) type
  @property({ type: Boolean }) autofocus = false
  @property({ type: Boolean }) disabled = false

  @state() isTyping = false

  static styles = [styles]

  static events = {
    onClbSearchChange: 'onClbSearchChange',
    onClbSearch: 'onClbSearch',
    onClbSearchEnter: 'onClbSearchEnter'
  }

  private getInputClasses = (): ClassInfo => ({
    [`input-container`]: true,
    [`input-container--disabled`]: this.disabled
  })

  protected updated(changedProps) {
    if (changedProps.has('value')) {
      this.detectTyping(this.value)
    }
  }

  protected firstUpdated() {
    this.removeOutlineStyle()
  }

  private _bubbleEvent(evt: Event): void {
    this.addOutlineStyle()

    const targetElement =
      this.shadowRoot.firstElementChild.querySelector('input')

    const searchClickElement =
      this.shadowRoot.firstElementChild.querySelector('#search-click')

    bubbleEvent(evt, targetElement)

    targetElement.addEventListener('input', function (e) {
      dispatchCustomEvent({
        eventName: ClbInputSearch.events.onClbSearchChange,
        eventOptions: { detail: { value: targetElement?.value } },
        targetElement,
        dispatcher: targetElement
      })

      e.stopImmediatePropagation()
    })

    searchClickElement.addEventListener('click', function (e) {
      dispatchCustomEvent({
        eventName: ClbInputSearch.events.onClbSearch,
        eventOptions: { detail: { value: targetElement?.value } },
        targetElement,
        dispatcher: targetElement
      })
      e.stopImmediatePropagation()
    })

    targetElement.addEventListener('keypress', function (e) {
      if (e.key === 'Enter') {
        dispatchCustomEvent({
          eventName: ClbInputSearch.events.onClbSearchEnter,
          eventOptions: { detail: { value: targetElement?.value } },
          targetElement,
          dispatcher: targetElement
        })
      }
      e.stopImmediatePropagation()
    })

    this.detectTyping(targetElement.value)
  }

  private detectTyping(value) {
    if (value.length > 0) {
      this.isTyping = true
    } else {
      this.isTyping = false
    }
  }

  private _clearInputDate() {
    const input = this.shadowRoot.firstElementChild.querySelector('input')
    const targetElement = this.shadowRoot.firstElementChild as HTMLElement

    input.value = ''
    this.isTyping = false
    targetElement.classList.remove('input-container--add-outline')
  }

  private addOutlineStyle() {
    const targetElement = this.shadowRoot.firstElementChild as HTMLElement
    const input = this.shadowRoot.firstElementChild.querySelector('input')

    input.addEventListener('click', () => {
      if (this.disabled === false) {
        targetElement.classList.add('input-container--add-outline')
      }
    })
  }

  private removeOutlineStyle() {
    const targetElement = this.shadowRoot.firstElementChild as HTMLElement
    const input = this.shadowRoot.firstElementChild.querySelector('input')

    document.body.addEventListener('click', (event: MouseEvent) => {
      const path = event.composedPath()
      if (!path.includes(input)) {
        targetElement.classList.remove('input-container--add-outline')
      }
    })
  }

  render() {
    return html`
      <div class="${classMap(this.getInputClasses())}">
        <i
          id="search-click"
          role="button"
          aria-label="buscar"
          aria-disabled="${this.disabled}"
          @click=${this._bubbleEvent}
        >
          ${unsafeSVG(icons['Search'])}
        </i>
        <input
          id="${this.id}"
          type="${this.type}"
          class="${classMap(this.getInputClasses())}"
          placeholder="${this.placeholder}"
          name="${this.name}"
          .value="${this.value}"
          aria-disabled="${this.disabled}"
          ?disabled=${this.disabled}
          @focus=${this._bubbleEvent}
          @blur=${this._bubbleEvent}
          @input=${this._bubbleEvent}
          aria-label="Caixa de pesquisa"
          ?autofocus=${this.autofocus}
        />
        <i
          id="close-click"
          class="${this.isTyping ? 'showCloseIcon' : 'hiddenCloseIcon'}"
          role="button"
          aria-label="limpar busca"
          aria-disabled="${this.disabled}"
          @click=${this._clearInputDate}
        >
          ${unsafeSVG(icons['Close'])}
        </i>
      </div>
    `
  }
}
