import {css} from 'lit';
export const styles = css`.multiselect__label {
  display: block;
  color: var(--color-neutral-600);
  font-size: var(--font-size-default);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-default);
  text-align: left;
  margin-bottom: var(--spacing-size-4xs);
}
.multiselect__label--message {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}
.multiselect__select {
  background-color: var(--color-opacity-default);
  border-style: solid;
  border-radius: var(--border-radius-default);
  border-width: var(--border-width-default);
  border-color: var(--color-neutral-300);
  min-height: 48px;
  padding: 0px var(--spacing-size-3xs);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.multiselect__select:hover {
  background-color: var(--color-opacity-hover);
  border-color: var(--color-brand-300);
}
.multiselect__select:hover .multiselect__input::placeholder {
  color: var(--color-contrast-brand);
}
.multiselect__select:hover .multiselect__chevron {
  color: var(--color-contrast-brand);
}
.multiselect__select clb-icon {
  display: inline-flex;
}
.multiselect__input-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: row-reverse;
}
.multiselect__input {
  border: none;
  outline: 0;
  padding: 0;
  background-color: transparent;
  flex: 1;
  width: unset;
  min-width: 32px;
  cursor: inherit;
  font-size: var(--font-size-default);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
}
.multiselect__input::placeholder {
  color: var(--color-neutral-300);
}
.multiselect__chevron {
  color: var(--color-neutral-600);
}
.multiselect clb-tag + clb-tag {
  display: inline-block;
  margin-left: var(--spacing-size-4xs);
}
.multiselect__helper {
  display: flex;
  margin-top: var(--spacing-size-4xs);
  font-size: var(--font-size-sm);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
  text-align: left;
}
.multiselect__helper clb-icon {
  margin-right: var(--spacing-size-5xs);
  display: inline-flex;
}
.multiselect--focused .multiselect__select {
  border-color: var(--color-brand-300);
  outline-style: solid;
  outline-offset: 2px;
  outline-color: var(--color-contrast-focused);
  outline-width: var(--border-width-sm);
  border-radius: var(--border-radius-default);
}
.multiselect--focused .multiselect__select .multiselect__input::placeholder, .multiselect--focused .multiselect__select:hover .multiselect__input::placeholder {
  color: var(--color-contrast-brand);
}
.multiselect--focused .multiselect__select .multiselect__chevron {
  color: var(--color-contrast-brand);
}
.multiselect--error .multiselect__select {
  background-color: var(--color-opacity-error);
  border-color: var(--color-feedback-danger-300);
}
.multiselect--error .multiselect__select .multiselect__input::placeholder, .multiselect--error .multiselect__select:hover .multiselect__input::placeholder {
  color: var(--color-feedback-danger-300);
}
.multiselect--error .multiselect__helper {
  color: var(--color-feedback-danger-300);
}
.multiselect--disabled {
  pointer-events: none;
}
.multiselect--disabled .multiselect__label {
  color: var(--color-neutral-300);
}
.multiselect--disabled .multiselect__select {
  background-color: var(--color-opacity-disabled);
  border-color: var(--color-neutral-300);
}
.multiselect--disabled .multiselect__select .multiselect__input::placeholder {
  color: var(--color-neutral-300);
}
.multiselect--disabled .multiselect__chevron {
  color: var(--color-neutral-300);
}
.multiselect--open .multiselect__select {
  border-color: var(--color-brand-300);
}
.multiselect--sm .multiselect__select {
  min-height: 36px;
}`;
export default styles;
