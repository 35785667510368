import { html, LitElement } from 'lit'
import { property } from 'lit/decorators.js'
import { ClassInfo, classMap } from 'lit/directives/class-map.js'

import { ClbMixin } from '@utils/ClbMixin'
import { registerElement } from '@utils/registerElement'

import { Position } from './props.types'
import styles from './styles.scss'

@registerElement('clb-tooltip')
export class ClbTooltip extends ClbMixin(LitElement) {
  @property({ type: String }) label = ''

  @property({ type: String }) position: Position = 'bottom'

  @property({ type: String }) firstPosition = ''

  static styles = [styles]

  private getClassAttributes = (): ClassInfo => ({
    [`tooltip`]: true,
    [`tooltip--${this.position}`]: true
  })

  connectedCallback() {
    super.connectedCallback()
    this.firstPosition = this.position
  }

  protected updated() {
    this.addEventListener('mouseenter', this.adjustTooltipPosition)
  }

  adjustPositionRight(right, left, width) {
    switch (this.position) {
      case 'right':
        if (right < width || (this.firstPosition == 'left' && left > width)) {
          this.position = 'left'
        }
        break
      case 'right-end':
        if (
          right < width ||
          (this.firstPosition == 'left-end' && left > width)
        ) {
          this.position = 'left-end'
        }
        break
      case 'right-start':
        if (
          right < width ||
          (this.firstPosition == 'left-start' && left > width)
        ) {
          this.position = 'left-start'
        }
        break
      default:
        break
    }
  }

  adjustPositionLeft(right, left, width) {
    switch (this.position) {
      case 'left':
        if (left < width || (this.firstPosition == 'right' && right > width)) {
          this.position = 'right'
        }
        break
      case 'left-end':
        if (
          left < width ||
          (this.firstPosition == 'right-end' && right > width)
        ) {
          this.position = 'right-end'
        }
        break
      case 'left-start':
        if (
          left < width ||
          (this.firstPosition == 'right-start' && right > width)
        ) {
          this.position = 'right-start'
        }
        break
      default:
        break
    }
  }

  adjustPositionBottom(top, bottom, height) {
    switch (this.position) {
      case 'bottom':
        if (bottom < height || (this.firstPosition == 'top' && top > height)) {
          this.position = 'top'
        }
        break
      case 'bottom-end':
        if (
          bottom < height ||
          (this.firstPosition == 'top-end' && top > height)
        ) {
          this.position = 'top-end'
        }
        break
      case 'bottom-start':
        if (
          bottom < height ||
          (this.firstPosition == 'top-start' && top > height)
        ) {
          this.position = 'top-start'
        }
        break
      default:
        break
    }
  }

  adjustPositionTop(top, bottom, height) {
    switch (this.position) {
      case 'top':
        if (
          top < height ||
          (this.firstPosition == 'bottom' && bottom > height)
        ) {
          this.position = 'bottom'
        }
        break
      case 'top-end':
        if (
          top < height ||
          (this.firstPosition == 'bottom-end' && bottom > height)
        ) {
          this.position = 'bottom-end'
        }
        break
      case 'top-start':
        if (
          top < height ||
          (this.firstPosition == 'bottom-start' && bottom > height)
        ) {
          this.position = 'bottom-start'
        }
        break
      default:
        break
    }
  }

  adjustTooltipPosition() {
    const tooltipElem = this.shadowRoot.querySelector('.tooltip__info')
    const tooltipElemChild = this.lastElementChild

    if (!tooltipElem || !tooltipElemChild) return

    const tooltipRect = tooltipElem.getBoundingClientRect()
    const tooltipElemChildRect = tooltipElemChild.getBoundingClientRect()

    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    const elementSpaceToLeft = tooltipElemChildRect.left - 50
    const elementSpaceToRight = windowWidth - (tooltipElemChildRect.right + 50)
    const elementSpaceToTop = tooltipElemChildRect.top - 50
    const elementSpaceToBottom =
      windowHeight - (tooltipElemChildRect.bottom - 50)

    if (this.position.includes('right')) {
      this.adjustPositionRight(
        elementSpaceToRight,
        elementSpaceToLeft,
        tooltipRect.width
      )
    } else if (this.position.includes('left')) {
      this.adjustPositionLeft(
        elementSpaceToRight,
        elementSpaceToLeft,
        tooltipRect.width
      )
    } else if (this.position.includes('bottom')) {
      this.adjustPositionBottom(
        elementSpaceToTop,
        elementSpaceToBottom,
        tooltipRect.height
      )
    } else if (this.position.includes('top')) {
      this.adjustPositionTop(
        elementSpaceToTop,
        elementSpaceToBottom,
        tooltipRect.height
      )
    }
  }

  render() {
    return html`
      <div class="${classMap(this.getClassAttributes())}">
        <div class="tooltip__info">
          <span class="tooltip__label">${this.label}</span>
        </div>
        <slot></slot>
      </div>
    `
  }
}
