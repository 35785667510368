import { html, LitElement } from 'lit'
import { property, query } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'

import { ClbCheckbox } from '@components/checkbox'

import { ClbMixin } from '@utils/ClbMixin'
import { createEvent, bubbleEvent } from '@utils/index'
import { registerElement } from '@utils/registerElement'

import styles from './styles.scss'

@registerElement('clb-dropdown-checkbox')
export class ClbDropdownCheckbox extends ClbMixin(LitElement) {
  @property({ type: String }) label = ''

  @property({ type: Boolean }) checked = false

  @property({ type: Boolean }) highlighted = false

  @property({ type: String }) name = ''

  @property({ type: String }) id = ''

  @property({ type: String }) value = ''

  @property({ type: Boolean }) indeterminate = false

  @property({ type: Boolean }) disabled = false

  @property({ type: Boolean }) required = false

  @query('clb-checkbox')
  protected _checkbox!: ClbCheckbox

  static styles = [styles]

  static events = {
    onClbDropdownChange: 'onClbDropdownChange'
  }

  _onSelect = (evt: CustomEvent) => {
    const checkBox = evt.target as HTMLInputElement
    const { checked, indeterminate } = checkBox
    evt.preventDefault()
    const event = createEvent(ClbDropdownCheckbox.events.onClbDropdownChange, {
      cancelable: true,
      ...evt,
      detail: { checked: checked, indeterminate }
    })
    bubbleEvent(event, evt.composedPath()[0] as Element)

    if (this.dispatchEvent(event)) {
      this.checked = checked
      this.indeterminate = indeterminate
    }
  }

  render() {
    return html`<div
      class="${classMap({
        'dropdown-checkbox-item--highlighted': this.highlighted
      })}"
    >
      <clb-checkbox
        id="${this.id}"
        @onClbChange=${this._onSelect}
        ?checked=${this.checked}
        label=${this.label}
        name="${this.name}"
        value="${this.value}"
        ?indeterminate=${this.indeterminate}
        ?disabled="${this.disabled}"
        ?required="${this.required}"
      ></clb-checkbox>
    </div>`
  }
}
