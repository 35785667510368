import {css} from 'lit';
export const styles = css`.select-combo {
  padding-bottom: var(--spacing-size-4xs);
}
.select-combo--lg {
  padding-top: var(--spacing-size-4xs);
}

.select-input {
  padding: 0 var(--spacing-size-3xs);
  background-color: var(--color-opacity-default);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) solid var(--color-neutral-300);
  border-width: var(--border-width-default);
  border-color: var(--color-neutral-300);
  color: var(--color-neutral-600);
  width: 100%;
  height: 100px;
}
.select-input:hover {
  background-color: var(--color-opacity-hover);
  border-color: var(--color-action-default);
  color: var(--color-neutral-600);
}
.select-input:focus {
  outline: 2px solid var(--color-contrast-focused);
  outline-offset: 2px;
  border-radius: var(--border-radius-default);
  border-color: var(--color-brand-300);
  border-width: var(--border-width-default);
}
.select-input:focus-visible {
  outline-offset: 2px;
  outline: 2px solid var(--color-contrast-focused);
  border-width: var(--border-width-sm);
  border-radius: var(--border-radius-default);
}
.select-input--error {
  background-color: var(--color-opacity-error);
  border-color: var(--color-feedback-danger-300);
  color: var(--color-feedback-danger-300);
}
.select-input--error:hover, .select-input--error .placeholder-value {
  background-color: var(--color-opacity-error);
  border-color: var(--color-feedback-danger-300);
  color: var(--color-feedback-danger-300);
}
.select-input--disabled {
  pointer-events: none;
  background: var(--color-opacity-disabled);
  color: var(--color-neutral-300);
}
.select-input--placeholder-initial {
  color: var(--color-neutral-300);
}
.select-input--lg {
  height: 3rem;
}
.select-input--sm {
  height: 2.25rem;
}
.select-input--lg .placeholder-value, .select-input .placeholder-icon {
  padding: var(--spacing-size-3xs) 0;
}
.select-input--sm .placeholder-value, .select-input .placeholder-icon {
  padding: var(--spacing-size-4xs) 0;
}

.placeholder-value {
  font-size: var(--font-size-default);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
}

.input-infos {
  display: flex;
  justify-content: space-between;
  color: var(--color-contrast-brand);
}
.input-infos svg {
  width: 21px;
  height: 16px;
  padding: var(--spacing-size-3xs) 0;
}

.select-label--lg {
  color: var(--color-neutral-600);
  font-size: var(--font-size-default);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-default);
  text-align: left;
}
.select-label--message {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}
.select-label--sm {
  display: none;
}
.select-label--disabled {
  color: var(--color-neutral-300);
}

option {
  background-color: var(--color-neutral-100);
  color: var(--color-neutral-600);
  font-size: var(--font-size-default);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-default);
  padding: var(--spacing-size-5xs) var(--spacing-size-3xs);
}
option:hover {
  background-color: var(--color-action-hover);
}

.select-helper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-size-5xs);
  font-size: var(--font-size-sm);
  font-family: var(--font-family-base);
}
.select-helper span {
  display: none;
}
.select-helper--error {
  color: var(--color-feedback-danger-300);
}
.select-helper--error span {
  display: inline-block;
  height: var(--font-size-default);
}
.select-helper--error span svg {
  height: var(--font-size-default);
  width: var(--font-size-default);
}
.select-helper--disabled {
  color: var(--color-neutral-300);
}`;
export default styles;
