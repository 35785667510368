import {css} from 'lit';
export const styles = css`.input-counter {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-regular);
  color: var(--color-neutral-600);
  line-height: var(--line-height-default);
  text-align: left;
}
.input-counter-label {
  display: inline-flex;
  flex-direction: row;
  gap: var(--spacing-size-5xs);
  font-weight: var(--font-weight-semibold);
}
.input-counter-label--message {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}
.input-counter-label--disabled {
  color: var(--color-neutral-300) !important;
}
.input-counter-label--sm {
  display: none;
}
.input-counter-input-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 135px;
  background-color: var(--color-opacity-default);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) solid var(--color-neutral-300);
  margin-top: var(--spacing-size-4xs);
  margin-bottom: var(--spacing-size-4xs);
}
.input-counter-input-container input {
  max-width: 80px;
  width: 30%;
  border: transparent;
  outline: transparent;
  background-color: transparent;
  font-family: var(--font-family-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-regular);
  color: var(--color-neutral-600);
  line-height: var(--line-height-default);
  text-align: center;
}
.input-counter-input-container input::placeholder {
  opacity: 1;
}
.input-counter-input-container input::-moz-selection {
  background: transparent;
}
.input-counter-input-container input::selection {
  background: transparent;
}
.input-counter-input-container clb-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.input-counter-input-container clb-icon:first-of-type {
  margin-left: var(--spacing-size-4xs);
}
.input-counter-input-container clb-icon:last-of-type {
  margin-right: var(--spacing-size-4xs);
}
.input-counter-input-container clb-icon:focus {
  outline: 2px solid var(--color-contrast-focused);
  border-radius: var(--border-radius-default);
  border-color: var(--color-brand-300);
  border-width: var(--border-width-default);
}
.input-counter-input-container clb-icon:focus-visible {
  outline: 2px solid var(--color-contrast-focused);
  border-color: var(--color-brand-300);
  border-width: var(--border-width-default);
}
.input-counter-input-container--lg {
  height: 48px;
}
.input-counter-input-container--sm {
  height: 36px;
}
.input-counter-input-container-icon-minus--disabled clb-icon:first-of-type {
  color: var(--color-neutral-300) !important;
  cursor: none;
  pointer-events: none;
}
.input-counter-input-container-icon-plus--disabled clb-icon:last-of-type {
  color: var(--color-neutral-300) !important;
  cursor: none;
  pointer-events: none;
}
.input-counter-input-container:focus {
  outline: transparent;
}
.input-counter-input-container:focus-visible {
  outline: transparent;
}
.input-counter-input-container--error {
  background-color: var(--color-opacity-error);
  border-color: var(--color-feedback-danger-300) !important;
}
.input-counter-input-container--error::placeholder {
  color: var(--color-feedback-danger-300) !important;
}
.input-counter-input-container--disabled {
  background: var(--color-opacity-disabled);
}
.input-counter-input-container--disabled:hover {
  border: var(--border-width-default) solid var(--color-neutral-300) !important;
}
.input-counter-input-container--disabled input {
  color: var(--color-neutral-300);
}
.input-counter-input-container--disabled input::placeholder {
  color: var(--color-neutral-300);
}
.input-counter-helper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-size-5xs);
  font-size: var(--font-size-sm);
}
.input-counter-helper span {
  display: none;
}
.input-counter-helper--error {
  color: var(--color-feedback-danger-300);
}
.input-counter-helper--error span {
  display: inline-block;
  height: var(--font-size-default);
}
.input-counter-helper--error span svg {
  height: var(--font-size-default);
  width: var(--font-size-default);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`;
export default styles;
